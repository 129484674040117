<template>
  <v-app>
    <v-container v-if="cleaner">
      <v-card>
        <v-card-title class="cleaner-header d-flex align-items-center">
          <div class="cleaner-header-avatar position-relative mr-4">
            <v-icon @click="$refs.cropModal.open()"
                    style="position: absolute; top: 0; right: 0; z-index: 1; padding-top: 0; margin-top: 0">
              mdi-camera
            </v-icon>
            <v-avatar :style="cleaner.photo ? '' : 'background: #0d86ff;'"
                      size="64"
                      class="mr-2"
            >
              <viewer v-if="cleaner.photo" :images="[cleaner.photo]">
                <img :src="cleaner.photo"
                     style="width: 64px; height: 64px; object-fit: cover"
                     alt="avatar">
              </viewer>
              <span v-else class="white--text headline"
                    style="color: #ffffff;font-size: 18px">{{ formatName(cleaner.name) }}</span>
            </v-avatar>
          </div>
          <div class="cleaner-header-title">
            <v-row>{{ cleaner.name }}</v-row>
            <v-row>
              <v-rating :value="cleaner.rating" color="amber" dense half-increments readonly size="14"/>
              <div class="grey--text ml-4" style="font-size: 12px; font-weight: normal">
                {{ cleaner.rating }}
              </div>
            </v-row>
          </div>
          <v-btn @click="$eventBus.$emit('sendWorkerNotifications', cleaner)"
                 class="ml-auto">Отправить уведомление
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" @change="onTabChange" show-arrows>
            <v-tab v-for="(title, key) in tabs" :key="key" :value="key">{{ title }}</v-tab>
          </v-tabs>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item key="info">
              <v-card height="auto">
                <v-card-text>
                  <v-form>
                    <v-row>
                      <v-col>
                        <v-row class="row-d-block">
                          <v-subheader>Фамилия Имя</v-subheader>
                          <v-text-field
                              type="text"
                              v-model="cleaner.name"
                              outlined
                              required
                              :rules="[v=>!/\d/ig.test(v) || 'Имя не должно содержать числа']"
                          />
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Примечание</v-subheader>
                          <v-textarea
                              :readonly="$store.state.user.city.code==='msk' && !['hr', 'alena', 'hr ira', 'polina hr', 'artur', 'hr marina'].includes($store.state.user.login.toLowerCase())"
                              v-model="cleaner.comment"
                              rows="2"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Тел.</v-subheader>
                          <v-row>
                            <v-text-field
                                v-model="cleaner.phone"
                                outlined
                                type="tel"
                                v-mask="$store.state.setMask(cleaner.phone)"/>
                            <div style="cursor: pointer;padding: 5px 15px;"
                                 :disabled="!cleaner.phone">
                              <a :href="'tel: ' + cleaner.phone">Позвонить</a>
                            </div>
                          </v-row>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Адрес</v-subheader>
                          <v-text-field
                              v-model="cleaner.address"
                              outlined
                              v-debounce:300ms.lock="findStreet"
                          ></v-text-field>
                          <v-list v-if="addresses.length"
                                  style="position: absolute;background: white;z-index: 1;border: 1px solid #eee">
                            <v-list-item v-for="address in addresses"><a @click="getMetro(address)"
                                                                         style="height: 48px;width:100%;padding-top: 13px;">{{
                                address.address
                              }}</a>
                            </v-list-item>
                          </v-list>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Метро</v-subheader>
                          <v-text-field
                              v-model="cleaner.metro"
                              outlined
                          />
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Линия метро</v-subheader>
                          <v-text-field
                              v-model="cleaner.metroLine"
                              outlined
                          />
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Cерия</v-subheader>
                          <v-text-field
                              v-model="cleaner.passport_series"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Номер</v-subheader>
                          <v-text-field
                              v-model="cleaner.passport_number"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Дата выдачи</v-subheader>
                          <v-text-field
                              v-model="cleaner.passport_date"
                              type="date"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Гражданство</v-subheader>
                          <v-select
                              v-model="cleaner.citizenship"
                              :items="['РФ', 'РБ', 'Киргизия', 'Казахстан', 'Другое']"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Депозит</v-subheader>
                          <v-text-field
                              :style="'padding-top: 0;margin: 0;background: '+(Number(cleaner.deposit)>=3000 ? 'transparent' : '#ffbcbc')"
                              outlined
                              v-model="cleaner.deposit"
                          />
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Документы</v-subheader>
                          <v-file-input
                              ref="file_input"
                              style="display: none"
                              v-model="cleaner.doc"
                              @change="setDoc"
                              prepend-icon="mdi-paperclip"
                              outlined
                              placeholder="Выберите документ"
                              hide-input
                          />
                        </v-row>
                        <template v-if="cleaner.documents">
                          <v-row v-for="(doc, index) in cleaner.documents" :key="index">
                          <span>
                            <a :href="doc.url" target="_blank">{{ doc.name }}</a>
                            <v-icon dense @click="deleteDoc(index)">mdi-close</v-icon>
                          </span>
                          </v-row>
                        </template>
                        <v-btn @click="$refs.file_input.$refs.input.click()">+ Добавить файл</v-btn>
                      </v-col>
                      <v-col>
                        <v-row class="row-d-block">
                          <v-subheader>Фикс процент (если 0, будет считаться автоматом)</v-subheader>
                          <v-text-field
                              v-model="cleaner.percentage_of_payment"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Дата оформления</v-subheader>
                          <Date v-model="cleaner.datehire" :disabled="!+$store.state.user.hr"/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Дата увольнения</v-subheader>
                          <Date v-model="cleaner.datefire" :disabled="!+$store.state.user.hr"/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Статус</v-subheader>
                          <v-select
                              v-model="cleaner.status"
                              :items="cleanerStatuses"
                              item-value="value"
                              item-text="text"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block" v-if="+cleaner.status===2">
                          <v-subheader>Причина увольнения</v-subheader>
                          <v-checkbox v-for="(reason, i) in $store.state.fireReasons" :key="i"
                                      :label="reason" :value="reason" v-model="cleaner.fireReason" multiple/>
                          <!--          cleaner.fireReason - массив строк        -->
                        </v-row>
                        <v-row class="row-d-block" v-if="cleaner.status==2 || cleaner.comment_fire">
                          <v-subheader>Другое</v-subheader>
                          <v-textarea
                              v-model="cleaner.comment_fire"
                              rows="3"
                              outlined/>
                        </v-row>
                        <v-row class="row-d-block">
                          <v-subheader>Источник рекламы</v-subheader>
                          <v-select
                              v-model="cleaner.source"
                              outlined
                              :items="$store.state.user.cleanerSources"
                          />
                        </v-row>
                        <v-row>
                          <v-subheader>Пылесос</v-subheader>
                          <v-checkbox
                              v-model="cleaner.pilesos"
                              true-value="1"
                              false-value="0"/>
                        </v-row>
                        <v-row style="display: flex; align-items: center;">
                          <v-subheader>Уровень</v-subheader>
                          <v-row>
                            <v-col>
                              <v-checkbox
                                  v-model="cleaner.isNew"
                                  true-value="1"
                                  false-value="0">
                                <template v-slot:prepend>
                                  <v-subheader>Стажёр</v-subheader>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col>
                              <v-checkbox
                                  v-model="cleaner.isMain"
                                  true-value="2"
                                  false-value="0">
                                <template v-slot:prepend>
                                  <v-subheader>Наставник</v-subheader>
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-row>
                        </v-row>
                        <v-row style="display: flex; align-items: center;">
                          <v-subheader>Тренинг</v-subheader>
                          <v-row>
                            <v-col>
                              <v-checkbox
                                  v-model="cleaner.trainingClean"
                                  true-value="1"
                                  false-value="0">
                                <template v-slot:prepend>
                                  <v-subheader>Обычная</v-subheader>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col>
                              <v-checkbox
                                  v-model="cleaner.trainingGeneral"
                                  true-value="1"
                                  false-value="0">
                                <template v-slot:prepend>
                                  <v-subheader>Генеральная</v-subheader>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col>
                              <v-checkbox
                                  v-model="cleaner.trainingRemont"
                                  true-value="1"
                                  false-value="0">
                                <template v-slot:prepend>
                                  <v-subheader>После ремонта</v-subheader>
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-row>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-subheader>Доступ к приложению</v-subheader>
                            <v-checkbox
                                v-model="cleaner.confirm_status"
                                true-value="1"
                                false-value="0"/>
                          </v-col>
                          <v-col>
                            <v-subheader>В чате</v-subheader>
                            <v-checkbox
                                v-model="cleaner.in_chat"
                                true-value="1"
                                false-value="0"/>
                          </v-col>
                          <v-col>
                            <v-subheader>Блок</v-subheader>
                            <v-checkbox
                                v-model="cleaner.temp_block"
                                true-value="1"
                                false-value="0"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn :loading="loading" @click="save">Сохранить</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item key="balance">
              <v-card-text>
                <v-row>
                  <YearMonth v-model="balance_filterdate" @change="getPayment"/>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn class="v-btn-info" @click="openBalanceModal('1')">Ввод</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn class="v-btn-danger" @click="openBalanceModal('2')">Вычет</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn class="v-btn-success" @click="openBalanceModal('3')">Выдача</v-btn>
                  </v-col>
                </v-row>
                <v-row v-for="(total, index) in balance_total_table" :key="index" :class="total.class"
                       class="cleaner_balance_row">
                  <v-col cols="4">{{ total.title }}:</v-col>
                  <v-col cols="8"><strong>
                    {{
                      payments.months && payments.months[paymentMonth] && total.key(payments.months[paymentMonth]) ? total.key(payments.months[paymentMonth]) : '0'
                    }}
                    {{
                      $store.state.user.city && !total.no_currency ? $store.state.user.city.country.currency : ''
                    }}</strong></v-col>
                </v-row>
                <v-data-table
                    :headers="balance_header"
                    :items="payments.rows"
                    item-key="n_id"
                    :footer-props="{'items-per-page-options':[-1]}"
                    no-data-text="Баланс пуст"
                    loading-text="Загрузка..."
                    mobile-breakpoint="0"
                >
                  <template v-slot:item="{item}">
                    <tr
                        :style="`color:${['', '', 'red', 'green'][+item.type]};`+'background-color:'+(parseInt(item.datetime.date.split('-')[2])<=15 ? '#fffac9':'#c0f6ff')"
                        @click="editRowBalance(item.n_id, item.summ, item.comment)">
                      <td>{{ item.n_id }}</td>
                      <td>{{ $root.dateToRus(item.datetime.date) }} {{ item.datetime.time }}</td>
                      <td>
                        <span v-if="item.comment">{{ item.comment }}<br></span>

                        <span v-if="parseInt(item.order_id)" @click.stop="editOrder(item.order_id)"
                              style="cursor: pointer; color: blue">За заказ {{
                            item.order ? item.order.address.street + ', ' + item.order.address.house : ''
                          }}
                      </span>
                        <v-rating
                            v-if="item.order"
                            :value="parseInt(item.order.feedback) || 0"
                            color="amber"
                            dense
                            half-increments
                            readonly
                            size="14"
                        ></v-rating>
                      </td>
                      <td>
                        {{ item.summ }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item key="statistics">
              <v-card-text>
                <div class="my-4 subtitle-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Период статистики</th>
                        <th>Период действия процента</th>
                        <th>%</th>
                        <th>Средняя оценка</th>
                        <th>Выручка</th>
                        <th>Кол-во заказов</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="info in cleaner.percentInfo">
                        <td style="white-space: nowrap">{{ info.stats.period }}</td>
                        <td style="white-space: nowrap">{{ info.period }}</td>
                        <td>{{ info.percent }}</td>
                        <td style="white-space: nowrap">
                          <template v-if="info.stats.rate">
                            {{ info.stats.rate }} ({{ info.stats.rate_count }}
                            {{ $root.numberWord(info.stats.rate_count, ['оценка', 'оценки', 'оценок']) }})
                          </template>
                          <span v-else>Нет оценок</span>
                        </td>
                        <td style="white-space: nowrap">{{ $root.printCost(info.stats.total) }}</td>
                        <td>{{ info.stats.count }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-row>
                    <v-col>
                      Процент:
                      <template v-for="info in cleaner.percentInfo">
                        <br>{{ info.period }}: {{ info.percent }}%
                      </template>
                    </v-col>
                    <v-col>
                      Оценки
                      <div style="font-size: 12px;height: 20px;display: flex;gap: 5px;"
                           v-for="rate in cleaner.last_rates">
                        <span style="color: #3399ff;font-size: 18px">{{ rate.rate }}</span> {{
                          $root.dateToRus(rate.date)
                        }} <a target="_blank" :href="'#/orders/'+rate.n_id">№{{ rate.n_id }}</a>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Тип уборки</th>
                      <th>Кол-во заказов</th>
                      <th>Среднее время выполнения</th>
                      <th>Средняя выручка в час</th>
                      <th>Среднее кол-во клинеров на заказе</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in cleaner.orderDurationStat" :key="index">
                      <td>{{ $store.state.cleanTypes[row.type] }} {{ row.flattype }} комн</td>
                      <td>{{ row.count || 0 }}</td>
                      <td>
                        <template v-if="row.duration">
                          {{ row.durationHours }} ч {{ row.durationMinutes }} мин
                        </template>
                      </td>
                      <td>{{
                          parseInt(row.hourCost) ? parseInt(row.hourCost) + ' ' + $store.state.user.city.country.currency + '/ч' : ''
                        }}
                      </td>
                      <td>{{ row.cleanersCount }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item key="schedule">
              <ChangeWorkTime ref="ChangeWorkTime"/>
              <v-card-text style="max-width: 520px;" class="mx-auto">
                <div class="mb-4">
                <span
                    style="width: 20px;height:20px;border-radius: 20px;background:#9BF0F5;display: inline-block;vertical-align: middle;"></span>
                  - весь день
                  <span class="ml-2"
                        style="width: 20px;height:20px;border-radius: 20px;background:#ffe9a1;display: inline-block;vertical-align: middle;"></span>
                  - выбрано время
                  <span class="ml-2"
                        style="width: 20px;height:20px;border-radius: 20px;background:red;display: inline-block;vertical-align: middle;"></span>
                  - выходной
                </div>
                <v-row>
                  <vc-calendar @update:from-page="date=>getSchedule(`${date.year}-${$root.addZero(date.month)}-01`)"
                               ref="vc1" style="width: 100%" value="">
                    <template v-slot:day-content="{ day, dayEvents }">
                      <div @click="$refs.ChangeWorkTime.open(id, day.id, {...schedule[day.id]})"
                           :style="`background: ${scheduleDayColor(schedule[day.id])}`"
                           style="display: flex;justify-content: center;align-items: center;margin:5px auto;cursor:pointer;border-radius: 50%;width: 35px;height: 35px;"
                           v-on="dayEvents">
                        <div>{{ day.label }}</div>
                        <div style="position: absolute;
    bottom: 0;
    font-size: 9px;
    width: 100px;
    text-align: center;" v-if="schedule[day.id] && !schedule[day.id].isVocation && schedule[day.id].time_from">
                          {{ printWorkTime(schedule[day.id]) }}
                        </div>
                      </div>
                    </template>
                  </vc-calendar>
                </v-row>
                <div style="display: flex;gap: 10px;" class="mt-4">
                  <DatePeriod :intervals="[]" v-model="weekend"/>
                  <v-btn :loading="loading_vocation" @click="setVocations">В отпуск</v-btn>
                </div>
              </v-card-text>
            </v-tab-item>
            <v-tab-item key="orders">
              <v-card-text>
                <v-row>
                  <v-col class="mobile-col-basis">
                    <strong>Исполнен</strong>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="dateInterval"
                        name="dateInterval"
                        :items="dateIntervals"
                        placeholder="Выберите временной промежуток"
                        item-text="label"
                        item-value="value"
                        outlined
                        @change="applyDate">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-menu
                          ref="menu"
                          v-model="menu_datetimecleanFrom"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              outlined
                              :value="$root.dateToRus(datetimeclean_from)"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          />
                        </template>
                        <v-date-picker
                            v-model="datetimeclean_from"
                            no-title
                            scrollable
                            first-day-of-week="1"
                            locale="ru"
                            :reactive="true"
                            @input="menu_datetimecleanFrom = false"
                            @change="getOrders"
                        />
                      </v-menu>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-menu
                          ref="menu"
                          v-model="menu_datetimecleanTo"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              outlined
                              :value="$root.dateToRus(datetimeclean_to)"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          />
                        </template>
                        <v-date-picker
                            v-model="datetimeclean_to"
                            no-title
                            scrollable
                            first-day-of-week="1"
                            locale="ru"
                            @input="menu_datetimeTo = false"
                            @change="getOrders"/>
                      </v-menu>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-card-subtitle>Итого выручка: {{ total }}</v-card-subtitle>
                <v-data-table
                    :headers="orderHeaders"
                    :items="orders"
                    item-key="n_id"
                    :footer-props="{'items-per-page-options':[20]}"
                    no-data-text="Выручки нет"
                    loading-text="Загрузка..."
                    mobile-breakpoint="0">
                  <template v-slot:item="{item}">
                    <tr @click="editOrder(item.n_id)">
                      <td>{{ item.n_id }}</td>
                      <td>{{ $store.state.cleanTypes[item.type] }}</td>
                      <td>{{ item.options }}</td>
                      <td>{{ $root.dateToRus(item.datetimecleanObj.date) }}</td>
                      <td>{{ item.address.street }}, {{ item.address.house }}</td>
                      <td>{{ item.orderStatusString }}</td>
                      <td>{{ item.total }} {{ $store.state.user.city.country.currency }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item key="history">
              <v-timeline side="end">
                <v-timeline-item :color="item.color" fill-dot v-for="(item, i) in historyItems" :key="i">
                  <div class="d-block">
                    <strong class="mb-2">{{item.date.format('HH:mm, D MMM, YYYY')}}</strong>
                    <div>
                      <strong>{{item.title}}</strong>
                      <div class="text-caption">
                        {{item.subtitle}}
                      </div>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
<!--              пуши (просмотрено/нет)+,
                  выдачи зп, штрафы+
                  звонки
                  переходы по этапам+:
                    datetime_createObj, interview_dt, datetime_student, datehire, datefire,
                  -->
            </v-tab-item>
            <v-tab-item key="surveys">
              <v-row class="mb-2">
                <v-btn @click="openSurveyModal" style="background: #eee !important">Пройти опрос</v-btn>
              </v-row>
              <v-row>
                <v-btn @click="openCleanerSurveyModal" style="background: #eee !important">Пройденный опрос клинером
                </v-btn>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
    <CropModal ref="cropModal"/>
    <EditPayment ref="editPayment"/>
    <BalanceModal ref="balanceModal"/>
    <SurveyModal ref="survey_modal"/>
  </v-app>
</template>

<script>
import cleanerMixin from "@/mixins/cleanerMixin";
import CropModal from "../../views/cleaners/CropModal";
import YearMonth from "@/components/YearMonth";
import EditPayment from "@/views/cleaners/EditPayment";
import BalanceModal from "@/views/cleaners/BalanceModal";
import SurveyModal from "@/views/cleaners/SurveyModal";
import DatePeriod from "@/components/DatePeriod";
import ChangeWorkTime from "@/views/cleaners/ChangeWorkTime";
import Date from "@/components/Date";

export default {
  components: {Date, ChangeWorkTime, DatePeriod, SurveyModal, BalanceModal, EditPayment, YearMonth, CropModal},
  mixins: [cleanerMixin],
  data() {
    return {
      tab: 0,
      tabs: {
        info: 'Информация',
        balance: 'Баланс',
        statistics: 'Статистика',
        schedule: 'График работы',
        orders: 'Заказы и выручка',
        history: 'История',
        surveys: 'Анкетирование',
      }
    }
  },
  methods: {
    onTabChange(tabIndex) {
      let newTab = Object.keys(this.tabs)[tabIndex];
      this.$router.push({query: tabIndex ? {t: newTab} : {}});
      if (newTab === 'history') {
        this.historyRequests();
      }
    },
  },
  mounted() {
    if (this.$route.query.t) {
      this.tab = Object.keys(this.tabs).indexOf(this.$route.query.t) > 0 ? Object.keys(this.tabs).indexOf(this.$route.query.t) : 0;
    }
  }
}
</script>

<style scoped lang="scss">
.cleaner-header {
  &-title {
    font-size: 24px;
    font-weight: 500;
  }
}

.cleaner_balance_row.yellow {
  background-color: #fffac9 !important;
}

.cleaner_balance_row.blue {
  background-color: #c0f6ff !important;
}
</style>