<template>
  <v-dialog v-model="cropperDialog"
            width="500"
            data-app
            @input="onToggle()"
            style="z-index: 1115 !important;"
  >
    <v-card>
      <v-card-text style="padding-top: 20px">
        <v-row class="pb-4">
          <v-file-input
              v-model="photo"
              accept="image/*"
              @change="setImage"
              prepend-icon="mdi-camera"
              outlined
              placeholder="Выберите фото"
          ></v-file-input>
        </v-row>
        <v-row>
          <vue-cropper
              v-if="photo"
              ref="cropper"
              :src="imgSrc"
              alt="Аватар"
              :aspectRatio="1"
              :autoCropArea="0.5"
              :initialAspectRatio="1"
              :movable="false"
              :zoomable="false"
          >
          </vue-cropper>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveImage()" :loading="loading_img" :disabled="!photo">Сохранить</v-btn>
        <v-btn @click="cropperDialog = false">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "CropModal",
  components: {
    VueCropper
  },
  data() {
    return {
      cropperDialog: false,
      photo: null,
      imgSrc: '',
      loading_img: false,
    }
  },
  methods: {
    open() {
      this.cropperDialog = true;
    },
    onToggle() {
      this.photo = null
      this.imgSrc = null
    },
    setImage() {
      let file = this.photo;
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    saveImage() {
      this.loading_img = true;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData()
        formData.append('file', blob, 'photo.jpeg')
        this.axios.post('https://cleanbros.ru/upload', formData)
            .then(response => {
              this.$eventBus.$emit('getCleanerPhoto', response)
              this.photo = null
              this.imgSrc = null
              this.loading_img = false
              this.cropperDialog = false;
            })
        .catch(e=>{
          this.$root.notify(e, 'error')
          this.loading_img = false
        })
      })
    },
  }
}
</script>

<style scoped>

</style>