<template>
  <v-row>
    <v-col cols="9" class="mobile-col-basis" v-if="dateIntervals.length">
      <v-btn v-for="(item, index) in dateIntervals" v-if="!item.values"
             class="mr-1"
             :key="index"
             :style="date[0]===item.from && date[1]===item.from ? '' : 'background: #eee !important;'"
             small
             @click="date = [item.from, item.from];onchange()">
        {{ item.label }}
      </v-btn>
      <v-menu
          v-else
          open-on-hover
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              style="background: #eee !important;margin:5px"
              @click="date = [item.from, item.to];onchange()"
          >
            {{ item.label }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              dense
              v-for="(subItem, index) in item.values"
              :key="index"
              @click="date = [subItem.from, subItem.to];onchange()"
          >
            <v-list-item-title>{{ subItem.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col v-if="!simple" :cols="$vuetify.breakpoint.width < 480 || (intervals && intervals.length===0) ? 6 : 2">
      <v-row>
        <v-menu
            ref="menu"
            transition="scale-transition"
            left
            style="z-index: 1027;"
            :close-on-content-click="false"
            bottom
            nudge-bottom="35"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                :value="$root.outputRange(date)"
                readonly
                v-bind="attrs"
                style="width: 100%;"
                v-on="on"
            />
            <v-btn
                icon
                small
                @click.stop="date=[];onchange()"
                color="grey"
                :style="date.length ? '' : 'display: none'"
                style="position: absolute;right: 5px;top: 10px;z-index: 100;"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </template>
          <v-date-picker
              v-model="date"
              no-title
              scrollable
              @change="onchange"
              range
              first-day-of-week="1"
              locale="ru"
          />
        </v-menu>
      </v-row>
    </v-col>
    <template v-else>
      <v-menu
          ref="menu"
          transition="scale-transition"
          left
          style="z-index: 1027;"
          :close-on-content-click="false"
          bottom
          nudge-bottom="35"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              outlined
              :value="$root.outputRange(date)"
              readonly
              v-bind="attrs"
              style="width: 100%;"
              v-on="on"
          />
          <v-btn
              icon
              small
              @click.stop="date=[];onchange()"
              color="grey"
              :style="date.length ? '' : 'display: none'"
              style="position: absolute;right: 5px;z-index: 100;"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-date-picker
            v-model="date"
            no-title
            scrollable
            @change="onchange"
            range
            first-day-of-week="1"
            locale="ru"
        />
      </v-menu>
    </template>
  </v-row>
</template>
<script>
export default {
  props: {
    intervals: {},
    value: {default: ''},
    simple: {default: false, type: Boolean},
  },
  methods: {
    onchange() {
      this.$emit('input', this.date);
      this.$emit('change', this.date);
    }
  },
  created() {

  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    let tomorrow = this.$moment().add(1, 'days').format('YYYY-MM-DD');
    let dayAfterTomorrow = this.$moment().add(2, 'days').format('YYYY-MM-DD');
    let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
    let dayBeforeYesterday = this.$moment().add(-2, 'days').format('YYYY-MM-DD');
    let currentWeekStart = this.$moment().startOf('isoWeek').format('YYYY-MM-DD');
    let currentWeekEnd = this.$moment().endOf('isoWeek').format('YYYY-MM-DD');

    let prevWeekStart = this.$moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    let prevWeekEnd = this.$moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
    let nextWeekStart = this.$moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    let nextWeekEnd = this.$moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');


    let currentMonthStart = this.$moment().startOf('month').format('YYYY-MM-DD');
    let currentMonthEnd = this.$moment().endOf('month').format('YYYY-MM-DD');
    let prevMonthStart = this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    let prevMonthEnd = this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    let nextMonthStart = this.$moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    let nextMonthEnd = this.$moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
    let intervals = this.intervals || ['prev_days', 'days', 'weeks', 'months'];
    let types = {
      prev_days: [
        {label: 'Позавчера', from: dayBeforeYesterday},
        {label: 'Вчера', from: yesterday},
      ],
      days: [
        {label: 'Сегодня', from: today},
        {label: 'Завтра', from: tomorrow},
      ],
      weeks: [
        {
          label: 'Эта неделя', from: currentWeekStart, to: currentWeekEnd, values: [
            {label: 'Пред неделя', from: prevWeekStart, to: prevWeekEnd},
            // {label: 'След неделя', from: nextWeekStart, to: nextWeekEnd},
          ]
        },
      ],
      months: [
        {
          label: 'Этот месяц', from: currentMonthStart, to: currentMonthEnd, values: [
            {label: 'Пред месяц', from: prevMonthStart, to: prevMonthEnd},
            // {label: 'След месяц', from: nextMonthStart, to: nextMonthEnd},
          ]
        },
      ]
    }
    let dateIntervals = [];
    intervals.forEach(type => dateIntervals.push(...types[type]));
    return {
      date: [this.value[0], this.value[1]],
      dateIntervals
    }
  }
}
</script>
