<template>
  <span>
  <template v-if="withAv">
    {{ $root.printCost(sum) }}
    <span style="white-space: nowrap">
      ({{ count }} шт, {{ $root.printCost(sum / count) }})
    </span>
  </template>
  <v-tooltip v-else top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
    {{ $root.printCost(sum) }}
    <span style="white-space: nowrap">
      ({{ count }} шт)
    </span>
  </span>
    </template>
    <span v-html="'Ср чек: '+ $root.printCost(sum/count)"></span>
  </v-tooltip>
    </span>
</template>
<script>

export default {
  name: "OrdersSum",
  props: {
    sum: {},
    withAv: {},
    count: {},
  },
}
</script>