<template>
  <v-row style="align-items: center;gap: 5px;">
    <v-btn small v-for="(btn, index) in btns" class="ml-2"
           :class="getDateVal(btnIndex(index)) === date ? '' : 'default'"
           @click="setAgo(btnIndex(index))">{{ btn || getMonthTitle(btnIndex(index)) }}
    </v-btn>
    <v-select dense outlined @change="onchange" style="max-width: 100px" class="mx-2" v-model="month"
              :items="months"></v-select>
    <v-select dense outlined @change="onchange" style="max-width: 81px" v-model="year" :items="years"></v-select>
  </v-row>
</template>
<script>
export default {
  name: 'YearMonth',
  props: ['value', 'nextMonth'],
  methods: {
    btnIndex(index) {
      return this.btns.length - 1 - index + (this.nextMonth ? -1 : 0);
    },
    onchange() {
      this.$emit('input', [this.year, this.month]);
      this.$emit('change', [this.year, this.month]);
    },
    setAgo(monthsAgo) {
      let date = this.$moment().subtract(monthsAgo, 'month');
      this.year = date.format('YYYY');
      this.month = date.format('MM');
      this.$emit('input', [this.year, this.month]);
      this.$emit('change', [this.year, this.month]);
    },
    getDateVal(monthsAgo) {
      let date = this.$moment().subtract(monthsAgo, 'month');
      return (date.format('YYYY') * 1) + '-' + (date.format('MM'))
    },
    getMonthTitle(monthsAgo) {
      let date = this.$moment().subtract(monthsAgo, 'month');
      return date.format('MMM') + ' ' + date.format('YY');
    },
  },
  computed: {
    date() {
      return this.year + '-' + this.month;
    }
  },
  created() {

  },
  data() {
    console.log(this.value);
    let months = [];
    for (let i = 0; i < 12; ++i) {
      let month = this.$moment('2021-01-01').add(i, "month").startOf("month");
      months.push({value: month.format('MM'), text: month.format('MMMM')});
    }
    let year = this.$moment().format('YYYY') * 1;
    let years = [];
    for (let i = year; i > 2015; --i) years.push(i + '');
    let btns = ['Этот месяц', 'Прошлый', '', ''].reverse();
    if (this.nextMonth) btns.push('След месяц');
    return {
      months,
      btns,
      years,
      year: this.value[0],
      month: this.value[1],
    }
  }
}
</script>
