<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0" style="justify-content: space-between">
          Дашборд
        </v-card-title>
        <v-row>
          <DatePeriod @change="reloadTable" v-model="date"/>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
                multiple
                outlined
                dense
                :items="[{text: 'Выбрать все', value: 'all'}, ...$store.state.toSelectItems($store.state.user.allCities, 'city', 'id')]"
                v-model="cities"
                @change="saveLocal($event)"
                no-data-text="Данных нет"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-text class="py-0">
          <v-data-table
              hover
              class="fixFirst2Columns"
              height="calc(100vh - 274px)"
              :items="rows.filter(r=>cities.includes('all') || cities.includes(r.id))"
              :headers="headers"
              item-key="id"
              fixed-header
              sort-by="revenue"
              sort-desc
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Данных нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr>
                <td>
                  <v-icon :color="batteryPercent(item).color">mdi-battery-{{ batteryPercent(item).percent }}</v-icon>
                  <div style="white-space: nowrap">{{ batteryPercent(item).success }} /
                    {{ batteryPercent(item).total }}
                  </div>
                </td>
                <td>
                  {{ item.city }}
                </td>
                <td style="white-space: nowrap">
                  {{ $root.printCost(item.plan) }}
                  <IconTip color="red" icon="alert" v-if="item.warnings.plan" :title="item.warnings.plan"></IconTip>
                  <br>{{ $root.printCost(item.revenue) }}
                  <Percent :value="item.revenue/item.plan-1"></Percent>
                </td>
                <td style="white-space: nowrap">
                  {{ item.cleanersInOrder.avg }}
                  <Percent :value="item.cleanersInOrder.avg/item.cleanersPlanAvg-1"></Percent>
                  <div style="color: #768192;font-size: 12px">{{ item.cleanersPlanAvg }}</div>
                </td>
                <td style="white-space: nowrap">
                  {{ $root.printCost(item.revenuePerCleanerInOrder) }}
                  <IconTip color="red" icon="alert" v-if="item.warnings.revenuePerCleanerInOrder"
                           :title="item.warnings.revenuePerCleanerInOrder"></IconTip>
                </td>
                <td>
                  {{ item.feedback }}
                  <IconTip color="red" icon="alert" v-if="item.warnings.feedback"
                           :title="item.warnings.feedback"></IconTip>
                </td>
                <td>
                  {{ item.feedbackAvg }}
                  <IconTip color="red" icon="alert" v-if="item.warnings.feedbackAvg"
                           :title="item.warnings.feedbackAvg"></IconTip>
                </td>
                <td>
                  <OrdersSum :count="item.receivedTotalCount" :sum="item.receivedTotalSum"/>
                </td>
                <td v-if="(+item.receivedCancelledCount) || (+item.receivedCancelledPercent)">
                  <a target="_blank"
                     :href="`/${item.code==='msk' ? '':item.code}#/orders?dateType=1&datetime_from=${date[0]}&datetime_to=${date[1]}&trash=1`">
                    <OrdersSum :count="item.receivedCancelledCount" :sum="item.receivedCancelledSum"/>
                    ({{ item.receivedCancelledPercent }}%)
                  </a>
                  <IconTip color="red" icon="alert" v-if="item.warnings.receivedCancelledPercent"
                           :title="item.warnings.receivedCancelledPercent"></IconTip>
                </td>
                <td v-else>---</td>
                <template v-for="(columns, i) in checkColumns">
                  <td :style="{background:['#e5eef3', '#f5f5f5', '#ecddec'][i]}" v-for="(column, j) in columns">
                    <SavingParam @change="val=>item.checkColumns[`${i}-${column}`] = val"
                                 v-model="item.checkColumns[`${i}-${column}`]" :city="item.id" type="checkbox"
                                 :initial-value="item.checkColumns[`${i}-${column}`]||''"
                                 :name="`workStatsCheckColumns_${i}-${column}`"/>
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import YearMonth from "../../components/YearMonth";
import Percent from "../../components/Percent";
import IconTip from "../../components/IconTip";
import DashboardStats from "../../components/DashboardStats";
import DatePeriod from "../../components/DatePeriod";
import OrdersSum from "../../components/OrdersSum";
import SavingParam from "../../components/SavingParam";

export default {
  name: "RegionsInstruments",
  components: {SavingParam, OrdersSum, DatePeriod, DashboardStats, IconTip, Percent, YearMonth},
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    let cities = this.$root.getData('regions_dashboard', 'cities') || ['all'].concat(this.$store.state.getObjectsField(this.$store.state.user.cities));
    return {
      rows: [],
      checkColumns: [],
      total: {},
      cities,
      citiesPrev: cities,
      date: [this.$moment().startOf('month').format('YYYY-MM-DD'), today],
      loadingTable: true,
      filter: {},
    }
  },
  computed: {
    headers() {
      let headers = [
        {text: '', value: 'battery'},
        {text: 'Регион', value: 'city'},
        {text: 'План / Выручка', value: 'plan'},
        {text: 'Клинеров с заказом / План (сред)', value: 'cleanersInOrder.avg'},
        {text: 'Выручка на клинера с заказом', value: 'revenuePerCleanerInOrder'},
        // {text: 'Баланс Facebook', value: 'facebook'},
        // {text: 'Баланс Yandex', value: 'yandex'},
        {text: 'Висит в обзвоне', value: 'feedback'},
        {text: 'Средняя оценка', value: 'feedbackAvg'},
        {text: 'Передано в город', value: 'receivedOrdersTotal'},
        {text: 'Отмененных из тех, что передали', value: 'receivedOrdersCancelled'},
      ];
      for (let i in this.checkColumns) {
        for (let j in this.checkColumns[i]) {
          headers.push({text: this.checkColumns[i][j], value: `${i}-${this.checkColumns[i][j]}`})
        }
      }
      return headers;
    },
  },
  methods: {
    batteryPercent(item) {
      let errors = Object.values(item.warnings).length;
      let total = 4;
      for (let i in item.checkColumns) {
        if (!(+item.checkColumns[i])) errors++;
        total++;
      }
      let success = total - errors;
      let percent = parseInt(success / total * 10) * 10;
      let color = '';
      if (percent < 30) color = 'red';
      else if (percent < 70) color = 'orange';
      else color = 'green';
      if (!percent) percent = 'outline';
      return {percent, success, total, errors, color};
    },
    percent(a, b, diff = false) {
      let res = parseInt(a / b * 100) || 0;
      if (res && diff) res = res - 100;
      let color = '';
      if (res < 0) {
        color = 'red';
      } else if (res > 0) {
        color = 'green';
        res = '+' + res;
      }
      return `<span style="color: ${color}">${res}%</span>`;
    },
    reloadTable() {
      this.rows = [];
      this.total = {};
      this.loadingTable = true;
      this.$store.state.server.request('regions/workStats', {date: this.date}, (data) => {
        this.rows = data.response;
        this.checkColumns = data.checkColumns;
        this.loadingTable = false;
      }, () => {
        this.loadingTable = false
      })
    },
    saveLocal() {
      this.$root.saveData('regions_dashboard', 'cities', this.cities)
      if (this.cities.includes('all') && !this.citiesPrev.includes('all')) this.cities = ['all'].concat(this.$store.state.getObjectsField(this.$store.state.user.allCities));
      else if (!this.cities.includes('all') && this.citiesPrev.includes('all')) this.cities = ['1'];
      this.citiesPrev = this.cities.slice();
    }
  },
  mounted() {
    this.reloadTable()
  }
}
</script>

<style lang="scss">
.fixFirst2Columns {
  td:nth-child(1),
  th:nth-child(1),
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2 !important;
    background: white;
  }
  td:nth-child(2),
  th:nth-child(2) {
    left: 69px;
  }
  th:nth-child(1),
  th:nth-child(2) {
    z-index: 3!important;
  }
}
</style>
