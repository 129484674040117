<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title>Push-рассылки
          <v-btn @click="openNewPushModal" class="ml-4 align-self-end">+ Добавить</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :loading="loading" :items="items" :headers="headers" @click:row="openNewPushModal">
            <template v-slot:item.text="{item}">
              {{item.text && item.text.length > 100 ? item.text.slice(0, 100) + '...' : item.text}}
            </template>
            <template v-slot:item.datetime_create="{item}">
              {{$moment.unix(item.datetime_create).format('DD.MM.YYYY')}}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <PushModal ref="push_modal" @on-create="get"/>
    </v-container>
  </v-app>
</template>

<script>
import PushModal from "@/views/clients/push/PushModal";
export default {
  name: "PushList",
  components: {PushModal},
  data() {
    return {
      headers: [
        {value: 'title', text: 'Название'},
        {value: 'text', text: 'Текст'},
        {value: 'promo', text: 'Промокод'},
        {value: 'datetime_create', text: 'Дата создания'},
      ],
      items: [],
      loading: false,
    }
  },
  computed: {
    store() {
      return this.$store.state;
    }
  },
  methods: {
    get() {
      this.loading = true;
      this.store.server.request('push/get', {}, (data)=>{
        this.loading = false;
        this.items = data.response;
      }, (data)=>{
        this.$root.notify(data.error, 'error');
        this.loading = false;
      });
    },
    openNewPushModal(item) {
      this.$router.push(`/clients/push/${item.id}`);
    }
  },
  mounted() {
    this.get();
  }
}
</script>

<style scoped>

</style>