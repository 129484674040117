<template>
  <v-app>
    <v-container>
      <v-card v-if="+$store.state.user.city.id===1">
        <v-card-title class="justify-space-between align-items-center">
          Рассчет зарплаты менеджера
          <v-btn v-if="+user.admin" @click="salaryCalcEditable = !salaryCalcEditable" text>Ред.</v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex mb-4">
            <v-text-field v-model.number="salary" placeholder="Желаемая зп" outlined class="mr-2"
            style="max-width: 200px;"/>
          </div>
          <div class="salary-info">
            <v-row>
              <v-col cols="8">KPI:при желаемой зарплате {{ $root.printCost(salary) }}/мес.
                сумма оформленных заказов в месяц должна составлять:
              </v-col>
              <v-col cols="4" class="justify-end">
                <strong>{{ $root.printCost(calculateKPI) }}</strong>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col cols="8">Оклад:</v-col>
              <v-col cols="4" class="justify-end ">
                <SavingParam v-if="salaryCalcEditable" :initial-value="salaryData.manager_salary"
                             :city="user.city.id" name="manager_salary"
                             @change="getBonusParams"
                />
                <template v-else>
                  {{ $root.printCost(salaryData.manager_salary) }}
                </template>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col cols="12">
                <div class="row-title">Личные бонусы:</div>
                <ul class="list-unstyled">
                  <li>
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox v-model="isCalculateBonuses[0]"/>
                      </v-col>
                      <v-col cols="8">
                        Самый высокий средний чек за месяц среди всех менеджеров
                        <SavingParam v-if="salaryCalcEditable" :initial-value="bonusInfo.avrgBill"
                                     :city="user.city.id" name="avrgBill"
                                     @change="getBonusParams" style="max-width: 100px;"/>
                        <span v-else>(>{{ $root.printCost(bonusInfo.avrgBill) }})</span>
                      </v-col>
                      <v-col cols="3" class="justify-end d-flex pr-0">
                        <SavingParam v-if="salaryCalcEditable" :initial-value="salaryData.bonus_average_bill"
                                     :city="user.city.id" name="bonus_average_bill"
                                     @change="getBonusParams"/>
                        <template v-else>
                          +{{ $root.printCost(salaryData.bonus_average_bill) }}
                        </template>
                      </v-col>
                    </v-row>
                    <v-divider/>
                  </li>
                  <li>
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox v-model="isCalculateBonuses[1]"/>
                      </v-col>
                      <v-col cols="8">
                        Средний чек не ниже:
                        <ul>
                          <li>быстрая -
                            <SavingParam v-if="salaryCalcEditable" :initial-value="bonusInfo.avgBillStandart"
                                         :city="user.city.id" name="avgBillStandart"
                                         @change="getBonusParams" style="max-width: 100px;"/>
                            <span v-else>{{ $root.printCost(bonusInfo.avgBillStandart) }}</span>
                          </li>
                          <li>генеральная -
                            <SavingParam v-if="salaryCalcEditable" :initial-value="bonusInfo.avgBillGeneral"
                                         :city="user.city.id" name="avgBillGeneral"
                                         @change="getBonusParams" style="max-width: 100px;"/>
                            <span>{{ $root.printCost(bonusInfo.avgBillGeneral) }}</span>
                          </li>
                          <li>После ремонта -
                            <SavingParam v-if="salaryCalcEditable" :initial-value="bonusInfo.avgBillRemont"
                                         :city="user.city.id" name="avgBillRemont"
                                         @change="getBonusParams" style="max-width: 100px;"/>
                            <span>{{ $root.printCost(bonusInfo.avgBillRemont) }}  </span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col cols="3" class="justify-end d-flex pr-0">
                        <SavingParam v-if="salaryCalcEditable"
                                     :initial-value="salaryData.bonus_average_bill_cleanings"
                                     :city="user.city.id" name="bonus_average_bill_cleanings"
                                     @change="getBonusParams"/>
                        <template v-else>
                          +{{ $root.printCost(salaryData.bonus_average_bill_cleanings) }}
                        </template>
                      </v-col>
                    </v-row>
                    <v-divider/>
                  </li>
                  <li>
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox v-model="isCalculateBonuses[2]"/>
                      </v-col>
                      <v-col cols="8">Удаленных заказов не больше
                        <SavingParam v-if="salaryCalcEditable"
                                     :initial-value="bonusInfo.deleted_orders"
                                     :city="user.city.id" name="deleted_orders"
                                     @change="getBonusParams" style="max-width: 100px;"/>
                        <span v-else>{{ bonusInfo.deleted_orders }}%</span>
                      </v-col>
                      <v-col cols="3" class="justify-end d-flex pr-0">
                        <SavingParam v-if="salaryCalcEditable" :initial-value="salaryData.bonus_deleted_orders"
                                     :city="user.city.id" name="bonus_deleted_orders"
                                     @change="getBonusParams"/>
                        <template v-else>
                          +{{ $root.printCost(salaryData.bonus_deleted_orders) }}
                        </template>
                      </v-col>
                    </v-row>
                  </li>
                  <li>
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox v-model="isCalculateBonuses[3]"/>
                      </v-col>
                      <v-col cols="8">Рейтинг от клиентов >
                        <SavingParam v-if="salaryCalcEditable"
                                     :initial-value="bonusInfo.rate"
                                     :city="user.city.id" name="rate"
                                     @change="getBonusParams" style="max-width: 100px;"/>
                        <span v-else>{{ bonusInfo.rate }}</span>
                      </v-col>
                      <v-col cols="3" class="justify-end d-flex pr-0">
                        <SavingParam v-if="salaryCalcEditable" :initial-value="salaryData.bonus_rate"
                                     :city="user.city.id" name="bonus_rate"
                                     @change="getBonusParams"/>
                        <template v-else>
                          +{{ $root.printCost(salaryData.bonus_rate) }}
                        </template>
                      </v-col>
                    </v-row>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import SavingParam from "@/components/SavingParam";
import managerMixins from "@/mixins/managerMixins";
export default {
  name: "ManagerSalaryCalculator",
  components: {SavingParam},
  mixins: [managerMixins]
}
</script>

<style scoped>

</style>