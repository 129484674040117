<template>
  <v-dialog data-app v-model="opened" max-width="400px" @input="onToggle">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>{{ type ? types[type] : '' }}</v-card-title>
      <v-card-text>
        <v-form ref="balanceForm">
          <v-radio-group row v-model="periodManual">
            <v-subheader>Период, к которому привязать</v-subheader>
            <v-radio label="Автоматически" :value="0"/>
            <v-radio label="Указать вручную" :value="1"/>
          </v-radio-group>
          <template v-if="periodManual">
            <YearMonth v-model="periodMonth"/>
            <v-radio-group row v-model="periodMonthHalf">
              <v-subheader>Половина месяца</v-subheader>
              <v-radio label="Первая" :value="1"/>
              <v-radio label="Вторая" :value="2"/>
            </v-radio-group>
          </template>
          <v-row class="row-d-block">
            <v-subheader>Сумма</v-subheader>
            <v-text-field
                v-model="sum"
                outlined
            />
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Комментарий (клинер видит в приложении!)</v-subheader>
            <v-textarea
                v-model="comment"
                outlined
                rows="4"/>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import YearMonth from "../../components/YearMonth";

export default {
  name: "BalanceModal",
  components: {YearMonth},
  data() {
    return {
      periodMonth: [],
      periodMonthHalf: 1,
      loading: false,
      opened: false,
      periodManual: 0,
      cleaner_id: null,
      type: null,
      year: null,
      month: null,
      types: {
        1: 'Ввод',
        2: 'Вычет',
        3: 'Выдача',
      },
      sum: null,
      comment: null,

    }
  },
  methods: {
    open(type, cleaner_id, year, month) {
      this.type = type;
      this.cleaner_id = cleaner_id;
      this.year = year;
      this.month = month;
      this.comment = '';
      this.periodManual = 0;
      this.periodMonthHalf = 1;
      this.periodMonth = [year, this.$root.addZero(month)];
      this.opened = true;
      this.sum = '';
    },
    onToggle() {
      this.sum = null;
      this.comment = null;
    },
    save() {
      this.loading = true;
      this.$store.state.server.request('cleaner/addPayment', {
        id: this.cleaner_id,
        year: this.periodMonth[0],
        month: this.periodMonth[1],
        sum: this.sum,
        comment: this.comment,
        half: this.periodManual ? this.periodMonthHalf : 0,
        type: this.type
      }, () => {
        this.$root.notify(this.types[this.type] + ' добавлен(а)', 'success')
        this.$eventBus.$emit('balanceModalState')
        this.loading = false
        this.opened = false;
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading = false
      })
    },
  },
  watch: {}
}
</script>

<style scoped>

</style>
