<template>
  <v-container data-app :retain-focus="false">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Push-рассылка: {{item.title}}</v-card-title>
          <v-card-actions>
            <v-btn @click="openPushEditModal">Редактировать</v-btn>
          </v-card-actions>
          <v-card-text>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <PushModal ref="push_modal"/>
  </v-container>
</template>

<script>
import PushModal from "@/views/clients/push/PushModal";

export default {
  name: "PushPage",
  components: {PushModal},
  data() {
    return {
      loading: false,
      item: {}
    }
  },
  computed: {
    store() {
      return this.$store.state;
    }
  },
  methods: {
    openPushEditModal(id = null) {
      this.$refs.push_modal.open({id: id || this.item.id});
      this.$router.push({query: {edit: '1'}}).catch(e=>{});
    },
    get() {
      this.loading = true;
      this.store.server.request(`push/get/${this.$route.params.id}`, {}, (data) => {
        this.loading = false;
        this.item = data.response;
      }, (data) => {
        this.loading = false;
        this.$root.notify(data.error, 'error');
      })
    }
  },
  mounted() {
    this.get();
    if (this.$route.query.edit) {
      this.openPushEditModal(this.$route.params.id);
    }
  }
}
</script>

<style scoped>

</style>