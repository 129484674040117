<template>
  <div>
    <template v-if="!metroOnly || !value.metro">
      {{ value.street }}, {{ value.house }}
    </template>
    <template v-if="value.metro">
      <br v-if="!inline">
      <span style="width: 10px;height: 10px;border-radius: 32px;display: inline-block;vertical-align: middle" v-if="value.metroColor"
            :style="`background:#${value.metroColor.split('#').join('') || 'eee'}`"></span>
      {{ value.metro }}
    </template>
  </div>
</template>
<script>

export default {
  name: "Address",
  props: {
    value: {},
    inline: {default: false, type: Boolean},
    metroOnly: {default: false, type: Boolean},
  },
}
</script>
