<template>
  <div class="record-card">
    <div class="record-card-header" :class="{'record-card-header-winners':item.winners && item.winners.length }">
      <img src="/img/records_icons/gold_cup.png" alt="" width="84">
      <div class="record-card-header-label">Номинация</div>
      <div class="record-card-header-title">{{ item.title }}</div>
    </div>
    <div v-if="item.top" class="record-card-winners">
      <div class="record-card-winner" v-for="(winner, i) in item.top" :key="i"
           :class="`record-card-winner-place-${i+1}`">
        <div class="record-card-winner-place">
          <img :src="`/img/records_icons/place_${i+1}.png`" alt="" width="74">
        </div>
        <div class="record-card-info">
          <div class="record-card-info-label">Город</div>
          <div class="record-card-info-value">{{ $store.state.user.allCities.find(el => el.id === winner.city).city }}
          </div>
        </div>
        <div class="record-card-info">
          <div class="record-card-info-label">Результат</div>
          <div class="record-card-info-value">{{ $root.numberWithSpaces(winner.value) }}
            {{ typeof item.units === 'undefined' ? $store.state.user.city.country.currency : item.units }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="record-card-content">
      <div class="record-card-info">
        <div class="record-card-info-label">Город</div>
        <div class="record-card-info-value">{{ $store.state.user.allCities.find(el => el.id === item.value.city).city }}
        </div>
      </div>
      <div class="record-card-info">
        <div class="record-card-info-label">Результат</div>
        <div class="record-card-info-value">{{ $root.numberWithSpaces(item.value.value) }}
          {{ typeof item.units === 'undefined' ? $store.state.user.city.country.currency : item.units }}
        </div>
      </div>
      <div class="record-card-info">
        <div class="record-card-info-label">Месяц</div>
        <div class="record-card-info-value">{{ $moment(item.value.date).format('MMMM') }}</div>
      </div>
      <div class="record-card-info">
        <div class="record-card-info-label">Год</div>
        <div class="record-card-info-value">{{ $moment(item.value.date).format('YYYY') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordCard",
  props: ['item'],
}
</script>

<style scoped lang="scss">
.record-card {
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #000;

  &-header {
    margin-bottom: 48px;
    text-align: center;

    img {
      margin-bottom: 28px;
    }

    &-label {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 700;
      color: #AA6B1B;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      color: #000;
    }

    &-winners {
      .record-card-header-label {
        color: #5D5D5D;
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 32px;
    width: 100%;
  }

  &-info {
    text-align: center;

    &-label {
      margin-bottom: 10px;
      font-size: 24px;
      color: #000;
    }

    &-value {
      color: #AA6B1B;
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 32px;
    }
  }

  &-winner {
    @media screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 40px;
      padding-bottom: 20px;
      grid-gap: 20px;
    }

    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 74px 1fr 1fr;
    grid-gap: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &-place-1 {
      .record-card-info-value {
        color: #AA6B1B;
      }
    }

    &-place-2 {
      .record-card-info-value {
        color: #5D5D5D;
      }
    }

    &-place-3 {
      .record-card-info-value {
        color: #9F4410;
      }
    }
  }
}
</style>
