<template>
  <div style="display: flex;flex-wrap: wrap;flex-direction: row">
    <div v-for="(obj, key) in translation" :key="key" style="flex-grow:1">
      <template v-if="obj.id && emptyOnly && translationPrev[key][lang]"></template>
      <div v-else-if="obj.id" style="background: #fff;margin: 5px;padding: 10px">
        <label v-html="obj.ru"></label>
        <br>
        <label style="color: #2eb85c;cursor: pointer" v-if="obj[lang+'_auto']"
               @click="translation[key][lang]=obj[lang+'_auto'];save(translation[key])">Автоперевод: <span
            style="color: #3399ff" v-html="obj[lang+'_auto']"></span></label>
        <vue-editor @blur="save(translation[key])" v-if="obj.ru.indexOf('<p>')>-1" v-model="translation[key][lang]"
                    :placeholder="'Перевод на '+title.toLowerCase()"/>
        <v-text-field @change="save(translation[key])" outlined v-else-if="obj.ru.indexOf('\n')===-1"
                      :placeholder="'Перевод на '+title.toLowerCase()" v-model="translation[key][lang]"/>
        <v-textarea @change="save(translation[key])" outlined v-else :placeholder="'Перевод на '+title.toLowerCase()"
                    v-model="translation[key][lang]"/>
      </div>
      <TranslationComponent v-else-if="typeof obj==='object'" :empty-only="emptyOnly" :translation="obj" :lang="lang"
                            :title="title" :translation-prev="translationPrev[key]"></TranslationComponent>
    </div>
  </div>
</template>
<script>

export default {
  name: "TranslationComponent",
  props: {
    translation: {},
    translationPrev: {},
    lang: {},
    emptyOnly: {},
    title: {},
  },
  methods: {
    save(item) {
      this.$store.state.server.request('translation/update', {
        lang: this.lang,
        value: item[this.lang],
        id: item.id
      }, () => {
        this.$root.notify('Перевод сохранен');
      }, (data) => {
        this.$root.notify(data.error || 'Не получилось сохранить перевод', 'error');
      });
    }
  }
}
</script>
