<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>
</template>

<script>
import globalMixins from "@/mixins/globalMixins";

export default {
  name: 'App',
  mixins: [globalMixins],
  data() {
    return {
      notification: true,
      last_update: 0,
    }
  },
  methods: {

    loadInfo() {
      if (this.$store.state.server.hasLoggedIn()) this.$store.state.server.request('user/info', {last_update: this.last_update}, (data) => {
        if (data.success) {
          this.last_update = data.datetime;
          this.$store.commit('updatingInfo', data.response);
          this.$store.commit('addNotifications', data.notifications);
        }
        setTimeout(() => this.loadInfo(), 3000);
      }, () => {
        setTimeout(() => this.loadInfo(), 3000);
      });
    }
  },
  mounted() {
    if (this.$store.state.server.hasLoggedIn()) {
      this.$store.state.server.request('user/get', {}, (data) => {
        this.last_update = data.datetime;
        data.notifications.forEach(notification => notification.hidden = 1);
        this.$store.commit('addNotifications', data.notifications);
        if (this.isLocalhost()) data.response.admin = true;
        this.$store.commit('set', ['user', data.response]);
        let masks = data.masks;
        for (let i in masks) masks[i] = masks[i].split('9').join('#');
        this.$store.commit('set', ['masks', masks]);
        document.title = 'CRM ' + data.user.city.city;
        this.loadInfo();
      }, (data) => {
        if (data.error) this.$eventBus.$emit('AlertModal', data.error, 'Ошибка', false,1);
      })
    }
    this.$store.state.sidebarShow === 'responsive' ? document.documentElement.style.setProperty('--sidebarWidth', '220px') : document.documentElement.style.setProperty('--sidebarWidth', '60px')
  },
  watch: {
    '$store.state.sidebarShow': function (sidebarSize) {
      sidebarSize === 'responsive' ? document.documentElement.style.setProperty('--sidebarWidth', '220px') : document.documentElement.style.setProperty('--sidebarWidth', '60px')
    }
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/font/gerbera/stylesheet.css';
@import 'assets/scss/style';
@import 'assets/scss/mobile_styles';

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!--<style src="vuetify/dist/vuetify.min.css"></style>-->


