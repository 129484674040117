<template>
  <v-menu
      ref="menu"
      v-model="opened"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="date ? date.split('-').reverse().join('.') : ''"
          readonly
          outlined
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          :clearable="clearable"
          :placeholder="placeholder"
          @change="$emit('input', '');$emit('change', '');opened = false;"
      />
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable
        :min="min"
        first-day-of-week="1"
        locale="ru"
        :reactive="true"
        @change="$emit('input', date);$emit('change', date);opened = false;"
    />
  </v-menu>
</template>
<script>
export default {
  name: 'Date',
  props: ['value', 'disabled', 'clearable', 'placeholder', 'min'],
  data() {
    return {
      opened: false,
      date: this.value,
    }
  },
  watch: {
    value() {
      this.date = this.value;
    }
  }
}
</script>
