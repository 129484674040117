<template>
  <v-tooltip :right="position==='right'" :top="position==='top'">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :size="size" :style="iconStyle" :color="color" v-bind="attrs" v-on="on">mdi-{{ icon }}</v-icon>
    </template>
    <span v-html="title"></span>
  </v-tooltip>
</template>
<script>

export default {
  name: "IconTip",
  props: {
    title: {},
    icon: {},
    iconStyle: {},
    size: {},
    position: {default: 'right'},
    color: {},
  },
}
</script>
