import 'core-js/stable'
import '@mdi/font/css/materialdesignicons.css'
import 'vue-flash-message/dist/vue-flash-message.min.css'
import Vue from 'vue'
import IdleVue from "idle-vue";
import App from './App'
import InfoTip from "./components/InfoTip";
import router from './router'
import CoreuiVue from '@coreui/vue'
import Vue2EditorEsm from "vue2-editor";

Vue.use(Vue2EditorEsm);

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import {RichTextEditorPlugin} from '@syncfusion/ej2-vue-richtexteditor';

Vue.use(RichTextEditorPlugin);

import VueTheMask from 'v-mask'
import store from './store'
import moment from 'moment'
import {VueEditor} from "vue2-editor";

moment.locale('ru')
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css';
import VueFlashMessage from 'vue-flash-message'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import vueDebounce from 'vue-debounce'
import YmapPlugin from 'vue-yandex-maps'


import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios)
Vue.use(vueDebounce)
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'


Vue.component('vc-date-picker', DatePicker)
Vue.component('vc-calendar', Calendar)

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

Vue.component(VueCropper);
Vue.component('vue-editor', VueEditor)
import {PhotoSwipe, PhotoSwipeGallery} from 'v-photoswipe'

Vue.use(PhotoSwipe)
Vue.use(PhotoSwipeGallery)
import 'regenerator-runtime/runtime'
import excel from 'vue-excel-export'
import ru from 'vuetify/es5/locale/ru'

Vue.use(excel)
const settings = {
  apiKey: 'e1e04e5e-96fa-43bd-ac38-da6cb13027db',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}
Vue.use(YmapPlugin, settings)

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 10 * 60 * 1000,
  startAtIdle: false
});
Vue.config.performance = true
if (!window.location.host.includes('localhost'))
  Vue.config.errorHandler = (err, vm, info) => {
    if (window.customErrorHandler) window.customErrorHandler({error: err.stack, info: info});
  };
Vue.use(CoreuiVue)
Vue.use(Vuetify)
Vue.use(VueFlashMessage);
Vue.use(VueTheMask)
Vue.use(Viewer)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$moment = moment;
let eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

// router.beforeEach((to, from, next)=>{
// console.log({from, to});
// Vue.prototype.$eventBus.$emit('pageChanged');
// next();
// })
Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    next();
    store.state.prevPage = from.path === '/' ? '' : from.path;
    if (from.path !== to.path) eventBus.$emit('pageChanged');
  }
})
new Vue({
  el: '#app',
  vuetify: new Vuetify({
    lang: {
      locales: {ru},
      current: 'ru'
    },
  }),

  router,
  store,
  icons: {
    iconfont: 'mdi',
  },
  template: '<App/>',
  components: {
    App, InfoTip,
  },
  methods: {
    notify(message, type = 'success') {
      this.flash(message, type, {
        timeout: 7000,
      })
      return true;
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    back(url = '/orders') {
      if (store.state.prevPage) router.back();
      else router.push(url);
    },
    saveData(page, name, data, forCurrentCity = false) {
      localStorage.setItem(`data_${forCurrentCity ? this.$store.state.user.city.id + '_' : ''}${page}_${name}`, JSON.stringify(data));
    },
    intval(number) {
      return Number(number) || 0;
    },
    managerName(id) {
      let user = store.state.user.managers.find(el => el.n_id === id)
      return user && user.login ? user.login : '';
    },
    colorBetween(color1, color2, coef) {
      let result = '#';
      for (let i = 0; i < 3; ++i) {
        let c1 = parseInt(color1.substring(i * 2, i * 2 + 2), 16);
        let c2 = parseInt(color2.substring(i * 2, i * 2 + 2), 16);
        let number = parseInt(c1 * coef + c2 * (1 - coef)).toString(16);
        if (number.length === 1) number = '0' + number;
        result += number;
      }
      console.log({color1, color2, coef, result})
      return result;
    },
    getData(page, name, forCurrentCity = false) {
      let val = localStorage.getItem(`data_${forCurrentCity ? this.$store.state.user.city.id + '_' : ''}${page}_${name}`);
      if (!val) return null;
      else return JSON.parse(val);
    },
    debounce(func, wait, immediate) {
      let timeout;

      return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
      };
    },
    addZero(number) {
      number = +number;
      return number <= 9 ? '0' + number : number;
    },
    numberWord(number, words) {
      if (number % 100 >= 10 && number % 100 < 20) return words[2];
      number %= 10;
      if (number === 1) return words[0];
      if (number > 1 && number < 5) return words[1];
      if (number >= 5 || number === 0) return words[2];
      return number
    },
    selectValues(array, skipEmpty = true) {
      let result = [];
      for (let i in array) {
        if (skipEmpty && !array[i]) continue;
        result.push({name: array[i], value: i})
      }
      return result;
    },
    round(x) {
      x = parseFloat(x);
      if (isNaN(x)) return 0;
      return Math.round(x * 100) / 100;
    },
    printTimeDiff(diff1, short = false) {
      let diff = parseInt(diff1) || 0;
      let prefix = diff >= 0 ? '' : '-';
      diff = Math.abs(diff);
      let mins = parseInt(diff / 60);
      let secs = diff % 60;
      if (short) {
        if (mins < 10) mins = '0' + mins;
        if (secs < 10) secs = '0' + secs;
        return prefix + [mins, secs].join(':');
      }
      return prefix + (mins ? mins + ' мин ' : '') + secs + ' сек';
    },
    numberWithSpaces: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    printCost(sum, withPlus = false, withSpaceBeforeCurrency = false) {
      sum = parseInt(sum);
      let minus = sum < 0;
      sum = Math.abs(sum);
      if (!sum) return 0 + this.$store.state.user.city.country.currency;
      sum += '';
      let l = sum.length;
      let part1 = sum.substr(0, l % 3);
      let part2 = sum.substr(l % 3);
      let parts = [];
      if (part1) parts.push(part1);
      part2 = part2.match(/.{1,3}/g);
      if (part2) parts.push(...part2);
      return (minus ? '-' : (withPlus ? '+' : '')) + parts.join(' ') + (withSpaceBeforeCurrency ? ' ' : '') + this.$store.state.user.city.country.currency;
    },
    logError(data) {
      if (location.hostname === 'localhost') {
        // this.notify('Проозошла ошибка, отправлена в консоль', 'error');
        console.error(data);
      } else {
        this.$store.state.server.request('auth/logError', data, () => {
          // this.notify('Проозошла ошибка и она была отправлена на сервер', 'error');
        });
      }
    },
    outputRange(value) {
      let string = '';
      if (value && value[0]) string += 'с ' + this.$root.dateToRus(value[0]);
      if (value && value[1]) string += ' по ' + this.$root.dateToRus(value[1]);
      return string;
    },
    cleanerInitials(fio) {
      if (!fio) return '';
      let parts = fio.trim().split(' ').filter(a => a);
      if (parts.length === 1) return fio;
      return parts[0] + ' ' + parts[1][0] + '.';
    },
    dateToRus(date, withoutYear = false, yearShort = false) {
      if (!date) return '';
      let year, month, day;
      [year, month, day] = date.split('-');
      try {
        if (withoutYear) return day + '.' + month;
        else if (yearShort) return day + '.' + month + '.' + (year % 1000);
        else return day + '.' + month + '.' + (year % 1000);
      } catch (e) {
        console.error(e, date)
      }
    },
  }
})
