<template>
  <v-app>
    <v-container>
      <v-card style="position: sticky;top:0;z-index: 1">
        <v-card-text>
          <v-select label="Перевод на" style="display: inline-block" :items="$store.state.toSelectItems(languages, 'title', 'code')"
                    v-model="language">
            <template v-slot:item="{item}">{{item.text}} <v-badge inline color="red" :content="$store.state.updatingInfo.translation[item.value]"/></template>
            <template v-slot:selection="{item}">{{item.text}} <v-badge inline color="red" :content="$store.state.updatingInfo.translation[item.value]"/></template>
          </v-select>
          <v-checkbox style="display: inline-block" class="ml-2" v-model="emptyOnly" label="Отображать только незаполненные"/>
          <div class="ml-4 mt-2" v-if="currentLanguage" style="display: inline-block">Не переведено на
            {{ currentLanguage.title.toLowerCase() }}:
            {{ untranslated.empty }} из
            {{ untranslated.empty + untranslated.done }} слов (длиной более 2 букв) ({{ untranslated.symbols_empty }} из
            {{ untranslated.symbols_empty + untranslated.symbols_done }} символов), фраз не переведено
            {{ untranslated.phrases_empty }} из {{ untranslated.phrases_done + untranslated.phrases_empty }}
          </div>
        </v-card-text>
      </v-card>
      <v-alert
          border="left"
          colored-border
          type="info"
          class="pt-1 mt-2 mb-0 pl-4"
          elevation="1"
      >
        <div class="text-h6" style="margin-top: -5px">Памятка для переводчика:</div>
        <ul>
          <li>Для удобства указан автоперевод (гугл переводчик). Если хотите его вставить в поле, просто нажмите на
            синий текст.
          </li>
          <li>На всех языках, кроме русского, компания "Братья Чистовы" называется Cleanbros. "Бюро чистоты Братья
            чистовы" и аналогичные фразы переводятся просто как "Cleanbros"
          </li>
          <li>Сохраняйте термины. Например, "стандартную/регулярную", "генеральную" уборку, "уборку после ремонта"
            надо переводить везде одинаково.
          </li>
          <li>Если непонятен контекст или есть другие вопросы - пишите нам</li>
        </ul>
      </v-alert>
      <TranslationComponent v-if="translation && currentLanguage" :empty-only="emptyOnly" :lang="language" :title="currentLanguage.title"
                            :translation="translation" :translation-prev="translationPrev"/>
    </v-container>
  </v-app>
</template>

<script>

import TranslationComponent from "./Component";

export default {
  name: "Translation",
  components: {TranslationComponent},
  data() {
    return {
      emptyOnly: true,
      translation: {},
      translationPrev: {},
      languages: [],
      language: 'tr',
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    untranslated() {
      return this.countWords(this.translation, this.language)
    },
    currentLanguage() {
      return this.languages.find(l=>l.code===this.language);
    }
  },
  methods: {
    countWords(obj, lang) {
      let count = {empty: 0, done: 0, phrases_empty: 0, phrases_done: 0, symbols_done: 0, symbols_empty: 0};
      if (obj.id) {
        count[obj[lang] ? 'done' : 'empty'] = this.countWordsStr(obj.ru);
        // count.symbols += obj.ru.length;
        count['symbols_' + (obj[lang] ? 'done' : 'empty')] += obj.ru.length;
        count['phrases_' + (obj[lang] ? 'done' : 'empty')] = 1;
        return count;
      }
      for (let i in obj) {
        let result = this.countWords(obj[i], lang);
        for (let j in result) count[j] += result[j];
      }
      return count;
    },
    countWordsStr(str) {
      let parts = str.split(' ');
      let count = 0;
      parts.forEach((word) => {
        if (word.length > 2 && !(+word)) count++;
      });
      return count;
    },
    load() {
      this.$store.state.server.request('translation/get', {}, (data) => {
        this.translation = data.response
        this.translationPrev = JSON.parse(JSON.stringify(data.response));
        this.languages = data.languages
      });
    },
  }
}
</script>

<style scoped>

</style>
