<template>
  <v-chip class="px-1" v-if="isFinite(this.value)" color="white" x-small :style="'color:'+(value ? value>0 ? 'green' : 'red' : '')">
    {{ value > 0 ? '+' : '' }}{{ Math.round(value * 100) }}%
  </v-chip>
</template>
<script>

export default {
  name: "Percent",
  props: ['value'],
}
</script>