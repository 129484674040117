<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Редактирование платежа</v-card-title>
      <v-card-text>
        <v-form
                ref="form">
          <v-row class="mb-4 row-d-block">
            <v-subheader>Сумма</v-subheader>
            <v-text-field
              v-model="sum"
              outlined></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Комментарий (клинер видит в приложении!)</v-subheader>
            <v-textarea
                v-model="comment"
                outlined
                rows="4"
                placeholder="Комментарий"></v-textarea>
          </v-row>
          <v-row v-if="error">
            <v-subheader style="color: red">{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditPayment",
  data() {
    return {
      opened: false,
      id: null,
      sum: null,
      comment: '',
      error: null,
    }
  },
  methods: {
    open() {
      this.opened = true
    },
    onToggle() {

    },
    save() {
      this.$store.state.server.request('cleaner/updatePayment', {id: this.id, sum: this.sum, comment: this.comment}, ()=>{
        this.$eventBus.$emit('balanceModalState')
        console.log('emit balanceModalState')
        this.$root.notify('Платеж обновлен', 'success')
        this.opened = false;
      }, (data)=>{
        this.error = data.error;
      })
    }
  }
}
</script>

<style scoped>

</style>