<template>
  <v-dialog v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>
        <v-icon v-if="mode !== 'surveys_list'" @click="back"
                class="mr-2" style="cursor: pointer">mdi-arrow-left</v-icon>
        Опросы</v-card-title>
      <v-card-text>
        <v-list v-if="mode === 'surveys_list'">
          <v-list-item class="survey-list-item" @click="mode = 'cleaner_surveys'; getCleanerSurveys()">
            <v-list-item-title>Опросы клинера</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item class="survey-list-item" @click="mode = 'manager_surveys'; getManagerSurvey()">
            <v-list-item-title>Опросы менеджера</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-list v-else-if="['manager_surveys', 'cleaner_surveys'].includes(mode)">
          <template v-if="surveys_loading">
            <v-skeleton-loader type="list-item" class="survey-list-item" v-for="i in 3" :key="i"/>
          </template>
          <template v-else>
            <v-list-item v-for="(item, i) in surveys" :key="i"
                         @click="mode =  mode === 'cleaner_surveys' ?'cleaner_survey' :'manager_survey'; selected_survey = item"
                         class="survey-list-item">
              <v-list-item-title>Опрос {{i+1}}: {{item.data.form[0].question}}</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list>
        <template v-else>
          <div v-if="surveys_loading" class="d-flex justify-center align-center">
            <v-progress-circular indeterminate/>
          </div>
          <template v-else>
            <v-col v-for="(question, i) in selected_survey.data.form" :key="i">
              <div class="question-title mb-2">{{ question.question }}</div>
              <v-rating v-if="question.hasOwnProperty('rate')" v-model="question.rate" :length="5" color="#FFB400"
                        size="46"/>
              <v-radio-group v-else-if="question.radio" v-model="question.radio_value">
                <v-radio v-for="radio in question.radio" :key="radio" :label="radio" :value="radio"/>
              </v-radio-group>
              <v-textarea v-if="question.hasOwnProperty('comment')" v-model="question.comment"
                          outlined
                          :placeholder="question.placeholder"
              />
            </v-col>
          </template>
        </template>
      </v-card-text>
      <!--      <v-card-title v-if="selected_survey.data.form.length">
              <v-icon v-if="params.type === 'c_survey'"
                      class="mr-2" style="cursor: pointer">mdi-arrow-left</v-icon>
              Опрос</v-card-title>
            <v-card-title v-else>Опросы</v-card-title>-->
      <!--      <v-card-text>
              <template v-if="!loading && params.type === 'c_survey' ? !c_surveys.length : !selected_survey.data.form.length">Клинер еще не прошел ни один опрос</template>
              <v-col v-else-if="isQuestions" v-for="(question, i) in selected_survey.data.form" :key="i">
                <div class="question-title mb-2">{{ question.question }}</div>
                <v-rating v-if="question.hasOwnProperty('rate')" v-model="question.rate" :length="5" color="#FFB400"
                          size="46"/>
                <v-radio-group v-else-if="question.radio" v-model="question.radio_value">
                  <v-radio v-for="radio in question.radio" :key="radio" :label="radio" :value="radio"/>
                </v-radio-group>
                <v-textarea v-if="question.hasOwnProperty('comment')" v-model="question.comment"
                            outlined
                            :placeholder="question.placeholder"
                />
              </v-col>
              <v-list v-else>
                <v-list-item v-for="(item, i) in c_surveys" :key="i"
                             @click="selected_survey = item; isQuestions = true"
                             class="survey-list-item">
                  <v-list-item-title>Опрос {{i+1}}: {{item.data.form[0].question}}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>-->
      <v-card-actions>
        <v-btn v-if="['cleaner_survey', 'manager_survey'].includes(mode)" @click="updateSurvey" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SurveyModal",
  data() {
    return {
      opened: false,
      loading: false,
      selected_survey: {data: {form: []}},
      params: {type: ''},
      surveys: [],
      mode: 'surveys_list',
      surveys_loading: false
      //mode: surveys_list - список опросов,
      //      cleaner_surveys - опросы клинера,
      //      manager_surveys - опросы манагера по клинеру,
      //      cleaner_survey - выбранный опрос из cleaner_surveys
      //      manager_survey - выбранный опрос из manager_surveys
    }
  },
  methods: {
    open(params) {
      this.params = {type: ''};
      this.selected_survey = {data: {form: []}};
      this.surveys = [];
      this.mode = 'surveys_list';
      this.opened = true;
      this.params = {...this.params, ...params};
      if (this.params.survey_id) {
        this.mode = 'cleaner_survey';
        this.getCleanerSurveys(this.params.survey_id);
      }
    },
    getManagerSurvey() {
      this.surveys_loading = true;
      this.$store.state.server.request(`cleaner/getManagerSurvey/${this.params.cleaner_id}`, {}, (data) => {
        this.surveys_loading = false;
        this.surveys = data.response;
      }, ()=>{
        this.surveys_loading = false;
      })
    },
    updateManagerSurvey() {
      this.loading = true;
      let editedSurveyIndex = this.surveys.findIndex(survey=>survey.type === this.selected_survey.type);
      this.surveys[editedSurveyIndex] = this.selected_survey;
      this.$store.state.server.request(`cleaner/updateManagerSurvey/${this.params.cleaner_id}`,
          {survey: this.surveys}, () => {
        this.loading = false;
        this.opened = false;
        this.$root.notify('Опрос сохранен!');
      }, () => {
        this.loading = false;
      });
    },
    getCleanerSurveys(survey_id = null) {
      this.surveys_loading = true;
      this.$store.state.server.request(`cleaner/getCleanerSurvey/${this.params.cleaner_id}`, {}, (data) => {
        this.surveys_loading = false;
        this.surveys = data.response;
        if (survey_id) {
          this.selected_survey = this.surveys.find(survey=>survey.id === survey_id);
        }
      }, ()=>{
        this.surveys_loading = false;
      })
    },
    updateCleanerSurvey() {
      this.loading = true;
      this.$store.state.server.request(`cleaner/updateCleanerSurvey/${this.params.cleaner_id}`,
          {survey: this.selected_survey.data.form, notif_id: this.selected_survey.id}, () => {
            this.loading = false;
            this.opened = false;
            this.$root.notify('Опрос сохранен!');
          }, () => {
            this.loading = false;
          })
    },
    updateSurvey() {
      if (this.mode === 'manager_survey') this.updateManagerSurvey();
      else if (this.mode === 'cleaner_survey') this.updateCleanerSurvey();
    },
    back() {
      if (['manager_surveys', 'cleaner_surveys'].includes(this.mode)) this.mode = 'surveys_list'
      else if (['manager_survey', 'cleaner_survey'].includes(this.mode))
        this.mode =this.mode === 'cleaner_survey' ?'cleaner_surveys' :'manager_surveys'
    }
  }
}
</script>

<style scoped>
.question-title {
  font-size: 15px;
  font-weight: 500;
}

.survey-list-item {
  border-bottom: 1px solid #f5f5f5;
  padding: 0;
  cursor: pointer;
}
</style>
