<template>
  <v-text-field v-if="type==='text'" outlined v-model="value" @change="update"/>
  <input v-else-if="type==='checkbox'" type="checkbox" v-model="value" @change="update"/>
  <!--  <v-checkbox v-else-if="type==='checkbox'" true-value="1" false-value="0" v-model="value" @change="update"/>-->
</template>
<script>

export default {
  name: "SavingParam",
  props: {
    name: {},
    type: {default: 'text'},
    initialValue: {default: null},
    city: {default: null},
  },
  data() {
    return {
      value: ''
    }
  },
  mounted() {
    if (this.initialValue === null) this.reload();
    else {
      this.value = this.initialValue;
      if (this.type === 'checkbox') this.value = (+this.value) ? 1 : 0;
    }
  },
  watch: {
    name() {
      if (this.initialValue === null) this.reload();
      else this.value = this.initialValue;
    },
    initialValue() {
      this.value = this.initialValue;
    }
  },
  methods: {
    update() {
      let value = this.value;
      if (this.type === 'checkbox') value = value ? '1' : '0';
      this.$store.state.server.request('user/setParam', {name: this.name, value, city: this.city}, () => {
        this.$emit('change', value);
        // this.$root.notify('Параметр сохранен');
      });
    },
    reload() {
      this.$store.state.server.request('user/getParam', {name: this.name, city: this.city}, (data) => {
        this.value = data.response;
      });
    }
  }
}
</script>
