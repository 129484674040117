<template>
  <span style="font-size: 12px;color: #fff;padding: 3px 5px;border-radius: 5px;height: 20px;line-height: 16px;"
        :style="{background: `hsl(${120-Math.min(120,parseInt(120*((needCleaners||0)/totalCleaners)))}, 75%, 50%)`}">
                    {{
      needCleaners || 0
    }} / {{ totalCleaners }}
                  </span>
</template>
<script>

export default {
  name: "CleanerSlotsUsage",
  props: ['needCleaners', 'totalCleaners'],
}
</script>
