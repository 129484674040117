<template>
  <span v-if="small" style="font-size: 14px;font-weight: normal;color: #2196f3;display: inline-flex;">
    <span>{{value }}</span>
    <v-icon color="amber"
              size="16">mdi-star</v-icon></span>
  <v-rating
      v-else
      :value="value"
      :color="color"
      dense
      background-color="transparent"
      half-increments
      readonly
      size="14"
  ></v-rating>
</template>
<script>

export default {
  name: "Rate",
  props: {
    value: Number,
    small: Boolean,
  },
  data() {
    let colors = ['#e07663', '#e57f2d', '#4dcbc9'];
    let color = this.value < 3 ? colors[0] : (this.value > 4 ? colors[2] : colors[1]);
    return {
      color
    }
  }
}
</script>
