export default {
  methods: {
    isLocalhost() {
      return location.host.indexOf('localhost') < -1;
    },
    clone(obj) {
      if (obj === null || typeof obj !== "object") return obj;
      let copy = Array.isArray(obj) ? [] : {};
      for (let i in obj) copy[i] = this.clone(obj[i]);
      return copy;
    },
  }
}