import globalMixins from "@/mixins/globalMixins";

export class Server {
  constructor() {
    this.city = this.parseRegion();
    if (!this.city) this.city = '';
    let prefix;
    if (globalMixins.methods.isLocalhost() && document.cookie.includes('galla')) prefix = 'http://localhost:8888';
    else if (location.host.indexOf('backup.') >= 0) prefix = 'https://backup.cleanbros.ru';
    else prefix = 'https://cleanbros.ru';
    this.url = prefix + `/${this.city ? this.city + '/' : ''}crmAPI/`;
    console.log(this.url);
    this.token = this.getToken();
    this.isIdle = false;
  }

  getToken() {
    return localStorage.getItem(`api_token`) || localStorage.getItem(`api_token_${this.city}`);
  }

  setToken(token) {
    this.token = token;
    localStorage.setItem(`api_token`, token);
  }

  logout() {
    this.setToken('');
    localStorage.setItem(`api_token_${this.city}`, '');
    location.reload()
  }

  parseRegion() {
    return location.pathname.split('/').slice(1).filter(c => c).join('/');
  }

  hasLoggedIn() {
    return !!this.getToken();
  }

  serialize(obj, prefix) {
    var str = [];
    for (var p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(typeof v == 'object' ? this.serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    }
    return str.join('&');
  }

  genUrl(method, data) {
    const requestParams = {
      ...data,
      token: this.token,
      crm: 2,
    };
    return this.url + method + '?pld=' + JSON.stringify(requestParams);
  }

  request(method, data, onSuccess, onError, onFinally) {
    if (this.isIdle) return onError();
    const requestParams = {
      ...data,
      token: this.token,
      crm: 3,
    };

    const checkForError = (response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    };

    return fetch(`${this.url + method}`, {
      method: 'POST',
      body: JSON.stringify(requestParams),
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then(checkForError)
      .then((response) => {
        if (!response.success && response.error === 'user is not logged in') {
          this.logout();
          window.location.reload()
          return;
        }
        if (!response.success && typeof onError === 'function') return onError(response);
        if (typeof onSuccess === 'function') onSuccess(response);
        return response;
      })
      .catch((error) => {
        if (typeof onError === 'function') onError(error);
        console.log(error)
        return error;
      })
      .finally(() => {
        if (typeof onFinally === 'function') onFinally();
      });
  }

}
