export default {
  data() {
    let years = [];
    for (let i = 0; i <= this.$moment().format('YYYY') - 2015; i++) {
      years.push((2015 + i).toString())
    }
    let today = this.$moment().format('YYYY-MM-DD');
    let tomorrow = this.$moment().add(1, 'days').format('YYYY-MM-DD');
    let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
    let firstMonthDay = this.$moment().startOf('month').format('YYYY-MM-DD');
    let lastMonthDay = this.$moment().endOf('month').format('YYYY-MM-DD');
    let firstLastMonthDay = this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    let lastLastMonthDay = this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    return {
      imgSrc: '',
      weekend: [],
      loading_street: false,
      id: null,
      opened: false,
      years,
      cleaner: {},
      addresses: [],
      total: 0,
      loading: false,
      loading_vocation: false,
      photo: [],
      cleanerStatuses: [
        {text: 'Новый', value: '3'},
        {text: 'Работает', value: '0'},
        {text: 'Не работает', value: '1'},
        {text: 'Уволен', value: '2'},
      ],
      schedule: {},
      scheduleMonth: '',
      balance_header: [
        {text: '#', value: 'n_id'},
        {text: 'Дата поступления', value: 'datetime'},
        {text: 'Адрес/Комментарий', value: 'comment'},
        {text: 'Сумма', value: 'summ'},
      ],
      balance_filterdate: [this.$moment().format('YYYY'), this.$moment().format('M')],
      balance_total_table: [
        // {title: 'Кол-во заказов', key: (row) => row.cleans},
        // {title: 'Мин уборок', key: (row) => row.min_cleans_count},
        {title: 'Общая зп', key: (row) => row.first_half.income + row.second_half.income, class: 'bold'},
        {title: 'Начислено 1-15', key: (row) => row.first_half.income, class: 'yellow'},
        {title: 'Выдано 1-15', key: (row) => row.first_half.salary, class: 'yellow'},
        {title: 'Остаток 1-15', key: (row) => row.first_half.to_pay, class: 'yellow'},
        {title: 'Начислено 16-%', key: (row) => row.second_half.income, class: 'blue'},
        {title: 'Выдано 16-%', key: (row) => row.second_half.salary, class: 'blue'},
        {title: 'Остаток 16-%', key: (row) => row.second_half.to_pay, class: 'blue'},
        {title: 'Штрафы', key: (row) => row.penalties, class: 'bold'},
        {title: 'Чаевые', key: (row) => row.tips, class: 'bold'},
        {title: 'Общий остаток', key: (row) => row.balance, class: 'bold'},
        {title: 'На руках', key: (row) => row.cash, class: 'bold'},
        {title: 'Заказов', key: (row) => row.cleans + ' шт', no_currency: 1},
      ],
      payments: {months: {}},
      paymentMonth: '',
      monthPaymentData: {},
      orderHeaders: [
        {value: 'n_id', text: '#'},
        {value: 'type', text: 'Тип'},
        {value: 'options', text: 'Опции'},
        {value: 'datetimecleanObj', text: 'Дата уборки'},
        {value: 'address', text: 'Адрес'},
        // {value: '', text: 'Оценка'},
        {value: 'orderStatusString', text: 'Статус'},
        // {value: '', text: 'Оформил'},
        {value: 'total', text: 'Выручка сотрудника'},
      ],
      orders: [],
      dateInterval: today,
      dateIntervals: [
        {label: 'Вчера', value: yesterday},
        {label: 'Сегодня', value: today},
        {label: 'Завтра', value: tomorrow},
        {label: 'Этот месяц', value: `${firstMonthDay} ${lastMonthDay}`},
        {label: 'Прошлый месяц', value: `${firstLastMonthDay} ${lastLastMonthDay}`},
      ],
      menu_datetimecleanFrom: false,
      datetimeclean_from: today,
      datetimeclean_to: today,
      menu_datetimecleanTo: false,
      pickerDate: null,
      notifications: [],
      rowPayments: [],
      calls: [],
    }
  },
  methods: {
    test(a, b, c, d) {
      console.log({a, b, c, d});
    },
    open() {
      this.opened = true;
      this.getCleaner()
    },
    close(clearQueryID = true) {
      this.opened = false;
      this.cleaner = {history: {}};
      if (this.$route.query.cleaner && clearQueryID) this.$router.push({query: {}});
    },
    onToggle() {
      this.cleaner = {};
      this.$router.push({query: {}})
      this.schedule = {}
      this.pickerDate = this.$moment().format('YYYY-MM')
    },
    getCleaner() {
      this.$store.state.server.request('cleaner/get/' + this.id, {}, (data) => {
        this.cleaner = data.response;
        if (this.cleaner.datehire === '0000-00-00') this.cleaner.datehire = '';
        this.getCleanerSurvey();
        try {
          this.cleaner.documents = this.cleaner.documents ? JSON.parse(this.cleaner.documents) : [];
        } catch (e) {
        }
        if (!Array.isArray(this.cleaner.documents)) this.cleaner.documents = []
        let currentMonth = this.$moment().format('YYYY-MM')
        this.getSchedule(currentMonth)
        this.getOrders()
        this.getPayment()
        if (this.$route.query.t === 'history') this.historyRequests();
        setTimeout(() => {
          this.$refs.chat_container.scrollToEnd(false, true);
        }, 100);
      }, () => {
        this.$root.notify('Клинер не найден', 'error')
        this.close();
      });
    },
    scheduleDayColor(info) {
      if (!info) return 'white';
      if (info.isVocation) return '#ff978f';
      else if (!info.time_from && !info.time_to) return '#9BF0F5';
      else return '#ffe9a1';
    },
    getSchedule(month) {
      let activeMonth_beginning = this.$moment(month).startOf('months').format('YYYY-MM-DD')
      let activeMonth_endind = this.$moment(month).endOf('months').format('YYYY-MM-DD')
      this.$store.state.server.request(`cleaner/getSchedule`, {
        id: this.id,
        from: activeMonth_beginning,
        to: activeMonth_endind
      }, (data) => {
        this.schedule = data.response;
      })
    },
    printWorkTime(schedule) {
      let time_from = schedule.time_from.split(':');
      let time_to = schedule.time_to.split(':');
      time_from = (+time_from[1]) ? [+time_from[0], time_from[1]].join(':') : +time_from[0];
      time_to = (+time_to[1]) ? [+time_to[0], time_to[1]].join(':') : +time_to[0];
      return [time_from, time_to].join(' - ');
    },
    getOrders() {
      this.orders = [];
      this.total = 0;
      let params = {id: this.id, datetimeclean_to: this.datetimeclean_to, datetimeclean_from: this.datetimeclean_from}
      this.$store.state.server.request(`cleaner/orders`, params, (data) => {
        this.orders = data.response
        this.total = 0;
        this.orders.forEach((order) => this.total += parseFloat(order.total))
        console.log('Заказы', data)
      })
    },
    getPayment() {
      this.$store.state.server.request('cleaner/getPayment', {
        id: this.id,
        year: this.balance_filterdate[0],
        month: this.balance_filterdate[1]
      }, (data) => {
        this.payments = data.response;
        for (let i in data.response.months) {
          this.paymentMonth = i;
          break;
        }
      })
    },

    formatName(name) {
      if (!name) return '';
      name = name.trim();
      name = name.split(' ');
      name = name.filter(i => i);
      if (name.length === 1) return name[0][0].toUpperCase()
      return (name[0][0] + name[1][0]).toUpperCase();
    },
    findStreet() {
      this.loading = true
      this.$store.state.server.request('cleaner/findAddress', {q: this.cleaner.address}, (data) => {
        this.addresses = data.response;
        this.loading = false
      }, () => {
        this.loading = false;
      })
    },
    setVocations() {
      let startVocation = this.$moment(this.weekend[0])
      let endVocation = this.$moment(this.weekend[1])
      let vocations = [];
      for (let current = startVocation; current <= endVocation; current.add(1, 'day')) {
        vocations.push(current.format('YYYY-MM-DD'))
      }
      let formatedVocations = {}
      vocations.forEach(el => {
        formatedVocations[el] = 1;
      })
      this.loading_vocation = true;
      this.$store.state.server.request(`cleaner/updateVocations`, {id: this.id, dates: formatedVocations}, () => {
        this.loading_vocation = false;
      }, () => {
        this.loading_vocation = false
      })
    },
    openBalanceModal(type) {
      this.$refs.balanceModal.open(type, this.id, this.balance_filterdate[0], this.balance_filterdate[1]);
    },
    applyDate() {
      this.datetimeclean_from = this.dateInterval
      this.datetimeclean_to = this.dateInterval
      if (this.dateInterval.split(' ').length === 1) {
        this.datetimeclean_from = this.dateInterval;
        this.datetimeclean_to = this.dateInterval;
      }
      if (this.dateInterval.split(' ').length > 1) {
        this.datetimeclean_from = this.dateInterval.split(' ')[0];
        this.datetimeclean_to = this.dateInterval.split(' ')[1];
      }
      this.getOrders();
    },
    save(closeAfterSave = true) {
      let params = {...this.cleaner};
      if (params.status == 2 && (!params.comment_fire && !params.fireReason.length) )
        return this.$root.notify('Укажите причину увольнения', 'error')
      this.loading = true;
      params.documents = JSON.stringify(this.cleaner.documents);
      delete params.orderDurationStat;
      this.$store.state.server.request(`cleaner/update/${this.id}`, params, (data) => {
        this.loading = false
        if (closeAfterSave) this.opened = false
        this.$root.notify('Клинер сохранен', 'success')
        this.$eventBus.$emit('cleanerUpdated');
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading = false
      })
    },
    editOrder(id) {
      this.$router.push(`/orders/${id}`);
    },
    editRowBalance(id, sum, comment) {
      const modal = this.$refs.editPayment;
      modal.id = id;
      modal.sum = sum;
      modal.comment = comment;
      modal.open();
    },
    getMetro(item) {
      this.cleaner.address = item.address;
      this.addresses = [];
      if (!parseFloat(item.lat)) return;
      this.$store.state.server.request('order/metro', {lat: item.lat, lng: item.lng}, (data) => {
        if (data.response && data.response.station) {
          this.cleaner.metro = data.response.station;
          this.cleaner.metroLine = data.response.line || '';
        }
      });
    },
    setDoc() {
      let file = this.cleaner.doc;
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.axios.post('https://cleanbros.ru/upload', formData)
        .then(data => {
          this.cleaner.doc = null;
          this.cleaner.documents.push({url: data.data.response, name: file.name});
          console.log(this.cleaner.documents)
          this.$root.notify('Документ добавлен', 'success')
          this.save(false);
        })
        .catch(e => {
          this.$root.notify(e, 'error')
        })
    },
    deleteDoc(key) {
      if (!confirm('Удалить документ?')) return;
      delete this.cleaner.documents[key]
      this.cleaner.documents = this.cleaner.documents.filter(i => i);
      this.$forceUpdate()
      this.save(false);
    },
    openSurveysModal() {
      this.$refs.survey_modal.open({cleaner_id: this.cleaner.n_id});
    },
    getCleanerSurvey() {
      this.$store.state.server.request(`cleaner/getCleanerSurvey/${this.cleaner.n_id}`)
    },
    historyRequests() {
      this.getCleanerNotifications();
      this.getPayments();
      this.getCalls();
    },
    getCleanerNotifications() {
      this.$store.state.server.request(`cleaner/getNotifications/${this.cleaner.n_id}`, {},
        (data)=>{
        this.notifications = data.response;
      });
    },
    getPayments() {
      this.$store.state.server.request(`cleaner/getPayments/${this.cleaner.n_id}`, {},
        (data)=>{
          this.rowPayments = data.response;
        });
    },
    getCalls() {
      this.$store.state.server.request(`cleaner/getCalls/${this.cleaner.n_id}`, {},
        (data)=>{
          this.calls = data.response;
        });
    }
  },
  computed: {
    historyItems() {
      let items = [];
      let prepairedNotifications = this.notifications.map(item=>{
        return {
          title: item.title,
          date: this.$moment.unix(item.datetime),
          datetime_seen: item.datetime_seen,
          type: 'notification',
          color: +item.datetime_seen ? '#c7c7c7' : '#D5D530'
        }
      });
      items.push(...prepairedNotifications);

      let payments = this.rowPayments.map(item=>{
        return {
          title: +item.type === 3 ? 'Выдача зп' : 'Штраф',
          subtitle: item.comment,
          date: this.$moment.unix(item.datetime),
          type: 'payment',
          color: +item.type === 3 ? '#b6ffba' : 'red',
        }
      });

      items.push(...payments);

      let stepItems = [
        this.cleaner.datetime_createObj ?
        {
          title: 'Добавлен в ЦРМ',
          date: this.$moment(`${this.cleaner.datetime_createObj.date}T${this.cleaner.datetime_createObj.time}`),
          type: 'step',
          color: '#9BF0F5'
        } : null,
        this.cleaner.interview_dt && this.cleaner.interview_dt.date ?
        {
          title: 'Собеседование',
          date: this.$moment(`${this.cleaner.interview_dt.date}T${this.cleaner.interview_dt.time}`),
          type: 'step',
          color: '#9BF0F5'
        } : null,
        {
          title: 'Назначен стажером',
          date: this.$moment.unix(this.cleaner.datetime_student),
          type: 'step',
          color: '#9BF0F5'
        },
        {
          title: 'Устроен на работу',
          date: this.$moment(this.cleaner.datehire),
          type: 'step',
          color: '#9BF0F5'
        },
        this.$moment(this.cleaner.datefire).isValid() ?
        {
          title: 'Уволен',
          date: this.$moment(this.cleaner.datefire),
          type: 'step',
          color: '#9BF0F5'
        } : null,
      ]
      items.push(...stepItems);
      let calls = this.calls.map(item=>{
        return {
          title: `Звонок (${item.datetime_end - item.datetime_start} cек.)`,
          date: this.$moment(item.datetime_create),
          type: 'call',
          color: '#301b5b'
        }
      })

      items.push(...calls);
      items = items.filter(item=>item).sort((a, b)=>this.$moment(a.date).isBefore(this.$moment(b.date)) ? 1 : -1);
      return items;
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getCleaner();
    }
  },
  created() {
    this.$eventBus.$on('changedSchedule', (date) => this.getSchedule(date))
    this.$eventBus.$on('balanceModalState', () => this.getPayment());
    this.$eventBus.$on('getCleanerPhoto', (data) => {
      this.cleaner.photo = data.data.response;
      this.save()
    })
  },
  destroyed() {
    this.$eventBus.$off('changedSchedule');
    this.$eventBus.$off('getCleanerPhoto');
    this.$eventBus.$off('balanceModalState');
  },
}