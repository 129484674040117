<template>
  <div>
    <a v-on:click.stop="$eventBus.$emit('openCleaner', value.n_id);">
      <RevenueIcon v-if="typeof value.revenue!=='undefined'"
                   :revenue="(value.revenue && value.revenue.revenue)? value.revenue.revenue:value.revenue"
                   :hours="(value.revenue && value.revenue.revenue)?value.revenue.hours: null"/>
      {{ value.name ? (fullName ? value.name : $root.cleanerInitials(value.name)) : value.phone }}
    </a>
  </div>
</template>
<script>

import RevenueIcon from "../views/cleaners/RevenueIcon";

export default {
  name: "CleanerLink",
  components: {RevenueIcon},
  props: {
    value: {},
    fullName: {type: Boolean}
  },
}
</script>
<style scoped>
a {
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  padding: 2px 5px;
  white-space: nowrap;
  background: #ced9e3;
  color: black !important;
  margin: 5px 0;
}

a:hover {
  background: rgba(206, 217, 227, 0.6);
  color: black;
}
</style>
