<template>
  <v-tooltip :top="this.top" :bottom="this.bottom">
    <template v-slot:activator="{ on, attrs }">
      <span style="text-decoration: underline" v-if="text" v-bind="attrs" v-on="on">{{text}}</span>
      <v-icon v-else size="18" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
    </template>
    <span style="max-width: 300px;display: block" v-html="value"></span>
  </v-tooltip>
</template>
<script>

export default {
  name: "InfoTip",
  data() {
    return {
      top: false,
      bottom: false,
    }
  },
  props: {
    value: {},
    position: {},
    text: {},
  },
  mounted() {
    this[this.position || 'top'] = true;
  }
}
</script>
