<template data-app>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Расписание на день</v-card-title>
        <v-card-actions>
          <div style="max-width: 100px">
            <Date v-model="date"/>
          </div>
          <v-btn class="ml-2" :loading="loading_save" @click="save">
            <v-icon>mdi-content-save-outline</v-icon>
            Сохранить
          </v-btn>
        </v-card-actions>
        <v-card-text style="position: relative;overflow: hidden">
          <div style="display: flex;position: absolute;width: calc(100% - 32px);height: 100%">
            <div style="min-width: 300px;pointer-events: none"></div>
            <div ref="ordersBlockWidth"
                 style="width: 100%;display: flex;justify-content: space-evenly;height: calc(100% - 20px)">
              <div v-for="i in (to - from)*2+1" style="display: flex;flex-direction: column;align-items: center;">
                <div style="margin-bottom: 10px">
                  <template v-if="(i - 1) % 2 === 0">
                    {{ parseInt((i - 1) / 2) + from }}
                  </template>
                  <div v-else style="display: flex;flex-direction: column;align-items: center;">
                    <v-subheader style="font-size: 10px;width: 70px;margin-top: -20px;height: 20px;gap: 3px">
                      <template v-for="(value, name) in coefs[parseInt((i - 1) / 2) + from+'']">
                        <v-tooltip top v-if="value!=1"
                                   :key="name">
                          <template v-slot:activator="{ on, attrs }">
                  <span style="flex-grow: 1;flex-basis: 0;text-align: center"
                        :style="value ? `color: ${parseFloat(value)>1 ? 'green' : 'red'}` : ''"
                        v-bind="attrs"
                        v-on="on">
                    {{
                      (parseFloat(value) > 1 ? '+' : '-') + Math.round(Math.abs(parseFloat(value) * 100 - 100))
                    }}
                  </span>
                          </template>
                          <span>{{ $store.state.cleanTypes[name] }} повыш коэф</span>
                        </v-tooltip>
                        <span v-else style="flex-grow: 1;flex-basis: 0;text-align: center">0</span>
                      </template>
                    </v-subheader>
                    <CleanerSlotsUsage v-if="time_stats[`${parseInt((i - 1) / 2) + from}`]"
                                       :need-cleaners="time_stats[`${parseInt((i - 1) / 2) + from}`].needCleaners"
                                       :total-cleaners="time_stats[`${parseInt((i - 1) / 2) + from}`].cleaners"/>
                  </div>
                </div>
                <div v-if="(i - 1) % 2 === 0" style="height: 100%;width: 2px;background: #eee"></div>
                <div v-else style="height: 100%;border-right:2px dotted #eee"></div>
              </div>
            </div>
          </div>
          <v-skeleton-loader v-if="loading" type="table"
                             style="padding-top: 20px;background:transparent;width: 100%;"/>
          <div v-else-if="cleaners.length" style="padding-top: 40px;position: relative;z-index: 1;">
            <div
                v-for="cleaner in cleaners"
                :key="cleaner.n_id"
                style="display: flex;border-bottom: 1px solid #eee">
              <div
                  style="min-width: 300px;width: 300px;padding: 1px 0;display: flex;flex-direction: column;position: relative;height: 85px;overflow: hidden">
                <div style="color: #333;display: flex;gap: 10px">
                  <CleanerLink :value="cleaner"/>
                  <template v-if="+cleaner.isNew">(стажёр)</template>
                  <div style="display: flex;align-items: center;">
                    <template v-if="cleaner.rate">
                      <span>{{ cleaner.rate }}</span>
                      <v-icon size="16" color="amber">mdi-star</v-icon>
                    </template>
                    <Address style="font-size: 14px;line-height: 14px;white-space: nowrap;margin-left: 10px"
                             v-if="cleaner.address"
                             :value="cleaner.address"
                             inline metro-only/>
                  </div>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" style="display: flex;gap: 10px;color: #3399ff">
                      <div v-for="revenue in cleaner.revenues">
                        <template v-if="revenue">{{ $root.printCost(revenue) }}</template>
                        <template v-else>---</template>
                      </div>
                    </div>
                  </template>
                  <span>Выручка клинера за 2 предыдущие дня</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden"
                         v-html="cleaner.comment.split('\n').join('<br>')"></div>
                  </template>
                  <span style="max-width: 300px;display: block"
                        v-html="cleaner.comment.split('\n').join('<br>')"></span>
                </v-tooltip>
              </div>
              <div style="width: calc(100% - 331px);display: flex;position:relative;margin-left: 14px;">
                <div v-if="+cleaner.n_id>0"
                     :style="{left: (strToHours(cleaner.worktime.time_from)-from)*hourLength+'px', width: (strToHours(cleaner.worktime.time_to) - strToHours(cleaner.worktime.time_from))*hourLength+'px'}"
                     style="background: rgba(8,227,81,0.4);height: 100%;position: absolute"></div>
                <template v-for="(order, index) in cleaner.orders">
                  <div v-if="index>0"
                       style="position: absolute;display: flex;flex-direction: column;align-items: center;height: 100%;justify-content: center;white-space: nowrap;"
                       :style="{left:(strToHours(cleaner.orders[index-1].datetimecleanObj.time)+cleaner.orders[index-1].calcDuration/60-from)*hourLength-6+'px', width: timeBeforeOrder(order, cleaner, index)*hourLength+'px'}">
                    <div>&#8592 {{ $root.printTimeDiff(timeBeforeOrder(order, cleaner, index) * 60, true) }} &#8594
                    </div>
                    <div v-if="order.distanceTo">{{ Math.round(order.distanceTo) }} км</div>
                  </div>
                  <div class="order"
                       :style="{width:(order.calcDuration/60*hourLength)+'px','left':(strToHours(order.datetimecleanObj.time)-from)*hourLength-6+'px'}"
                  >
                    <a style="display: flex;gap: 5px;color: inherit" :href="`#/orders/${order.n_id}`" target="_blank">
                      <span style="color: #aaa;font-size: 12px;white-space: nowrap">{{ order.datetimecleanObj.time }} - {{
                          $root.printTimeDiff(strToHours(order.datetimecleanObj.time) * 60 + order.calcDuration, true)
                        }} ({{
                          $root.printTimeDiff(order.calcDuration, true)
                        }}<template v-if="+order.realDuration>60"> / {{
                            $root.printTimeDiff(order.realDuration, true)
                          }}</template>)</span>
                      <span><img width="16" :src="`/img/${order.type}.png`"/></span>
                      <span v-if="+order.himchistka"><img width="16" :src="`/img/himchistka.png`"/></span>
                      <span v-if="+order.delivery"><img width="16" :src="`/img/ic_o.png`"/></span>
                      <span style="white-space: nowrap">{{ $root.printCost(order.total) }}</span>
                    </a>
                    <div style="display: flex;gap: 10px">
                      <a style="cursor: pointer;color: #3399ff;white-space: nowrap" @click="openCleaners(order)">
                        <span style="white-space: nowrap"
                              v-for="cleaner in getCleaners(order.n_id).filter(c=>c.n_id>0)">{{
                            $root.cleanerInitials(cleaner.name)
                          }} </span>
                        <span v-if="getCleaners(order.n_id).filter(c=>c.n_id>0).length===0">Выбрать клинеров</span>
                      </a>
                    </div>
                    <div>
                      <Address style="white-space: nowrap;font-size: 12px" inline metro-only :value="order.address"/>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="py-8">
            Нет данных за выбранный день. Попробуйте выбрать другой день
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <Cleaners ref="cleaners"/>
  </v-app>
</template>

<script>


import Date from "../../components/Date";
import Address from "../../components/Address";
import Rate from "../../components/Rate";
import CleanerLink from "../../components/CleanerLink";
import Timetable from "../../components/Timetable";
import Cleaners from "../orders/modals/Cleaners";
import CleanerSlotsUsage from "./components/CleanerSlotsUsage";

export default {
  name: "OrdersDay",
  components: {CleanerSlotsUsage, Cleaners, Timetable, CleanerLink, Rate, Address, Date},
  data() {
    return {
      orders: [],
      cleaners: [],
      from: 8,
      time_stats: {},
      coefs: {},
      cleanersModalOrderID: null,
      loading_save: false,
      date: this.$route.query.date || this.$moment().format('YYYY-MM-DD'),
      ordersBlockWidth: 100,
      to: 22,
      loading: false,
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    date(value) {
      this.$router.push({query: {date: value}});
      this.load();
    }
  },
  created() {
    this.$eventBus.$on('setOrderCleaners', (cleaners, order_id) => {
      cleaners = cleaners.filter(c => c);
      for (let i in this.orders) if (this.orders[i].n_id == order_id) {
        this.orders[i].cleaners = cleaners;
        break;
      }
      this.$forceUpdate();
    });
  },
  computed: {
    hourLength() {
      let val = this.ordersBlockWidth / (this.to - this.from + 1);
      console.log(val);
      return val + 3;
    }
  },
  methods: {
    save() {
      this.loading_save = true;
      let orders = {};
      for (let i in this.orders) {
        orders[this.orders[i].n_id] = this.orders[i].cleaners.length ? this.orders[i].cleaners.map(c => c.n_id) : [];
      }
      this.$store.state.server.request('order/setCleaners', {orders}, data => {
        this.$root.notify('Клинеры сохранены');
        this.onLoad(data);
        this.loading_save = false;
      }, (data) => {
        this.$root.notify(data && data.error ? data.error : 'Не получилось сохранить клинеров', 'error');
        this.loading_save = false;
      });
    },
    getCleaners(order_id) {
      return this.orders.find(o => o.n_id == order_id).cleaners;
    },
    openCleaners(order) {
      let data = {};
      data.chosenCleaners = order.cleaners || [];
      data.datetimecleanObj = order.datetimecleanObj;
      data.order_id = order.n_id;
      data.order = order;
      this.$refs.cleaners.open(data);
    },
    cleanersSort(c1, c2) {
      // if (+c1.n_id < 0 && +c2.n_id > 0) return 1;
      // if (+c1.n_id > 0 && +c2.n_id < 0) return 1;
      let v1 = parseInt(c1.ordersCount) || 0;
      let v2 = parseInt(c2.ordersCount) || 0;
      if (c1.revenue >= this.$store.state.user.revenuePerCleaner) v1 = 0.4;
      if (c2.revenue >= this.$store.state.user.revenuePerCleaner) v2 = 0.4;
      if (v1 > 1) v1 = 0.5;
      if (v2 > 1) v2 = 0.5;
      if (+c1.n_id < 0) v1 = 0.3;
      if (+c2.n_id < 0) v2 = 0.3;
      let c1Orders = c1.orders.map(o => o.n_id)
      let c2Orders = c2.orders.map(o => o.n_id)
      if (+c1.n_id && +c2.n_id && c1Orders.length && c2Orders.length) {
        let found = false;
        for (let i in c1Orders) if (c2Orders.includes(c1Orders[i])) {
          found = c1Orders[i];
          break;
        }
        if (found) {
          v1 += 0.01 / found;
          v2 += 0.01 / found;
        } else {
          v1 += 0.01 / c1Orders[0];
          v2 += 0.01 / c2Orders[0];
        }
      }
      return v2 - v1;
    },
    timeBeforeOrder(order, cleaner, orderIndex) {
      return this.strToHours(order.datetimecleanObj.time) - (this.strToHours(cleaner.orders[orderIndex - 1].datetimecleanObj.time) + cleaner.orders[orderIndex - 1].calcDuration / 60)
    },
    cleanerOrders(cleaner_id) {
      return this.orders.filter(o => o.cleaners.find(c => c.n_id == cleaner_id));
    },
    strToHours(str) {
      str = str.split(':');
      return parseInt(str[0]) + str[1] / 60
    },
    onLoad(data) {
      this.orders = data.orders
      this.cleaners = data.cleaners.sort(this.cleanersSort)
      this.from = data.from;
      this.to = data.to;
      this.coefs = data.coefs;
      this.time_stats = data.time_stats;
    },
    load() {
      this.orders = [];
      this.cleaners = [];
      this.loading = true;
      this.$store.state.server.request('order/day', {date: this.date}, data => {
        this.loading = false;
        this.onLoad(data);
        this.getOrdersBlockWidth();
      }, ()=>{
        this.loading = false;
      });
    },
    getOrdersBlockWidth() {
      if (!this.$refs.ordersBlockWidth || !this.$refs.ordersBlockWidth.offsetWidth) return setTimeout(this.getOrdersBlockWidth, 300);
      this.ordersBlockWidth = this.$refs.ordersBlockWidth.offsetWidth;
      console.log(this.ordersBlockWidth);
    }
  }
}
</script>

<style scoped lang="scss">
.order {
  background: #9BF0F5;
  position: absolute;
  height: calc(100% - 3px);
  top: 3px;
  border-radius: 5px;
  padding: 0 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 0 2px;

  &:hover {
    min-width: fit-content;
    z-index: 2;
  }
}
</style>
