<template>
  <v-dialog data-app v-model="opened" width="300px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="24">mdi-close</v-icon>
      <v-card-title>Время работы на {{ $moment(date).format('DD MMM') }}</v-card-title>
      <v-card-text>
        <v-row v-if="!schedule.isVocation">
          <v-col>
            <v-select @change="$refs.time_to.$el.querySelector('.v-input__slot').click()" v-model="schedule.time_from"
                      label="С" :items="$store.state.user.cleanersWorktime"/>
          </v-col>
          <v-col>
            <v-select ref="time_to" v-model="schedule.time_to" label="По "
                      :items="$store.state.user.cleanersWorktime.filter(item=>item>schedule.time_from)"/>
          </v-col>
        </v-row>
        <v-row>
          <v-checkbox label="Выходной" v-model="schedule.isVocation"/>
          <v-checkbox label="Работает весь день"
                      @click="()=>{if(!fulltime) {this.schedule.time_from = '';this.schedule.time_to = '';this.schedule.isVocation=false}}"
                      :input-value="fulltime"/>
          <v-checkbox label="Проставить на всю неделю" v-model="schedule.whole_week"/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading_save" @click="save();">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangeWorkTime",
  data() {
    return {
      opened: false,
      cleaner_id: 0,
      date: '',
      schedule: {},
      loading_save: false,
    }
  },
  methods: {
    open(cleaner_id, date, schedule) {
      this.opened = true
      this.cleaner_id = cleaner_id;
      this.date = date;
      this.schedule = schedule;
      this.loading_save = false;
    },
    save() {
      this.loading_save = true;
      this.$store.state.server.request('cleaner/setSchedule/' + this.cleaner_id, {
        date: this.date,
        schedule: this.schedule
      }, data => {
        this.opened = false;
        this.loading_save = false;
        this.$eventBus.$emit('changedSchedule', this.date);
      }, data => {
        if (!data) data = {};
        this.$root.notify(data.error || 'Не получилось сохранить');
        this.loading_save = false
      });
    },
  },
  computed: {
    fulltime() {
      return !this.schedule.isVocation && !this.schedule.time_from && !this.schedule.time_to;
    }
  }
}
</script>

<style scoped>

</style>
