<template>
  <v-dialog data-app v-model="opened" :retain-focus="false" max-width="500px" @click:outside="close">
    <v-container style="padding: 0; min-height: auto">
      <v-icon class="btn-close-modal" @click="close()" size="36">mdi-close</v-icon>
      <v-card style="margin-bottom: 0">
        <v-card-title>{{ item.id ? `Рассылка: ${item.title}` : 'Новая Push - рассылка' }}
          <v-menu ref="menu" transition="scale-transition" nudge-bottom="35" fixeds>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" small v-bind="attrs" v-on="on" style="background: #eee !important;">Превью уведомления</v-btn>
            </template>
            <div class="preview-notification">
              <div class="preview-notification-before">
                <img src="/img/logo_clnb_ro_mini_blue.svg" alt="">
              </div>
              <div class="preview-notification-body">
                <div class="preview-notification-title">{{item.title}}</div>
                <div class="preview-notification-text">{{item.text && item.text.length > 100 ? item.text.slice(0, 100) + '...' : item.text}}</div>
              </div>
              <div class="preview-notification-after">
                <span class="preview-notification-after-time">Сейчас</span>
                <img :src="item.img" alt="">
              </div>
            </div>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-row class="row-d-block">
            <v-subheader>Название</v-subheader>
            <v-text-field v-model="item.title" outlined placeholder="Например, Новый год"/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Текст</v-subheader>
            <v-textarea v-model="item.text" placeholder="Текст push-рассылки" outlined/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Промокод</v-subheader>
            <v-select v-model="item.promo" :loading="loading_promos" :items="promos" hide-no-data
                      item-text="title"
                      item-value="code"
                      outlined/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Изображение</v-subheader>
            <div @click="clickOnFileinput"
                 class="d-flex flex-column align-center justify-center pa-2 image-input"
                 :style="`background: url('${item.img}')`">
              <div class="d-flex flex-column pa-1 align-center text-center" style="background: #FFFFFF; border-radius: 4px; opacity: .9; width: 100px; ">
                <v-file-input
                    ref="file_input"
                    v-model="item.imageFile"
                    @change="setImage"
                    prepend-icon="mdi-paperclip"
                    outlined
                    hide-input
                    accept="image/*"
                    class="flex-grow-0"/>
                Выбрать изображение
              </div>
            </div>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Текст в кнопке уведомления</v-subheader>
            <v-text-field v-model="item.button_text" outlined placeholder="Например, Заказать уборку"/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Ссылка на раздел в приложении</v-subheader>
            <v-select v-model="item.link" outlined placeholder="Выберите раздел приложения" :items="appRoutes"
                      item-text="text" item-value="value"/>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn @click="create" :loading="loading_create">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: "PushModal",
  emits: ['on-create'],
  data() {
    return {
      opened: false,
      loading: false,
      loading_create: false,
      item: {},
      loading_promos: false,
      promos: [],
      appRoutes: [],
    }
  },
  computed: {
    store() {
      return this.$store.state;
    }
  },
  methods: {
    open(params) {
      this.item = {};
      this.promos = [];
      this.opened = true;
      if (params && params.id) this.get(params.id);
      this.getPromo();
    },
    close() {
      this.opened = false;
      this.$router.push({query: {}});
    },
    get(id) {
      this.loading = true;
      this.store.server.request(`push/get/${id}`, {}, (data) => {
        this.loading = false;
        this.item = data.response;
      }, (data) => {
        this.$root.notify(data.error, 'error');
        this.loading = false;
      });
    },
    create() {
      this.loading_create = true;
      const method = this.item.id ? `update/${this.item.id}` : 'create';
      this.store.server.request(`push/${method}`, this.item, () => {
        this.loading_create = false;
        this.$emit('on-create');
        this.close();
      })
    },
    getPromo() {
      this.loading_promos = true;
      this.store.server.request('promonew/get', {}, (data) => {
        this.loading_promos = false;
        this.promos = data.response;
      }, (data) => {
        this.$root.notify(data.error, 'error');
        this.loading_promos = false;
      })
    },
    setImage() {
      let file = this.item.imageFile;
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.axios.post('https://cleanbros.ru/upload', formData)
          .then(data => {
            this.item.imageFile = null;
            this.item.img = data.data.response;
          })
          .catch(e => {
            this.$root.notify(e, 'error')
          })
    },
    clickOnFileinput() {
      this.$refs.file_input.$refs.input.click();
    }
  },
}
</script>

<style scoped lang="scss">
.image-input {
  width: 185px;
  height: 185px;
  border-radius: 4px;
  border: 1px solid #dedede;
  cursor: pointer;
  background-size: cover;
}

.preview-notification {
  border-radius: 18px;
  background: #f5f5f5;
  padding: 10px 14px 10px 10px;
  display: flex;
  align-items: center;
  max-width: 370px;
  width: 100%;
  &-before {
    min-width: 38px;
    height: 38px;
    border-radius: 9px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-body {
    padding: 0 10px;
  }
  &-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
  }
  &-text {
    font-size: 15px;
    letter-spacing: -0.2px;
    line-height: 143%;
    display: -webkit-box;
    max-width: 256px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-after {
    text-align: right;
    &-time {
      display: block;
      margin-bottom: 4px;
      color: #838383;
      font-size: 13px;
    }
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}
</style>