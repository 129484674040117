<template>
  <div style="position: relative;overflow: hidden">
    <div style="display: flex;position: absolute;width: calc(100% - 32px);height: 100%">
      <div style="min-width: 300px;pointer-events: none"></div>
      <div ref="ordersBlockWidth"
           style="width: 100%;display: flex;justify-content: space-evenly;height: calc(100% - 20px)">
        <div v-for="i in (to - from)*2+1" style="display: flex;flex-direction: column;align-items: center;">
          <div style="margin-bottom: 10px">
            <template v-if="(i - 1) % 2 === 0">{{ parseInt((i - 1) / 2) + from }}{{
                (i - 1) % 2 === 1 ? ':30' : ''
              }}
            </template>
            <template v-else>&#8203;</template>
          </div>
          <div v-if="(i - 1) % 2 === 0" style="height: 100%;width: 2px;background: #eee"></div>
          <div v-else style="height: 100%;border-right:2px dotted #eee"></div>
        </div>
      </div>
    </div>
    <div v-if="rows.length" style="padding-top: 40px;position: relative;z-index: 1;">
      <div
          v-for="row in rows"
          :key="row.id"
          style="display: flex;border-bottom: 1px solid #eee">
        <div
            style="min-width: 300px;width: 300px;padding: 1px 0;display: flex;flex-direction: column;position: relative;height: 65px;overflow: hidden">
          <slot name="row-title" v-bind:row="row"></slot>
        </div>
        <div style="width: 100%;display: flex;position:relative;">
          <template v-for="(item, index) in row.items">
            <slot name="row-item" v-bind:row="row" v-bind:item="item" v-bind:index="index"></slot>
          </template>
        </div>
      </div>
    </div>
    <v-skeleton-loader v-else type="table"
                       style="padding-top: 20px;background:transparent;width: 100%;"/>
  </div>
</template>
<script>

export default {
  name: "Timetable",
  data() {
    return {

    }
  },
  props: {
    from: {default: 8},
    to: {default: 20},
    rows: [],
  },
}
</script>
