<template>
  <IconTip :color="color" :size="20" :icon="icon" :title="title"/>
</template>
<script>

import IconTip from "../../components/IconTip";

export default {
  name: "RevenueIcon",
  components: {IconTip},
  props: ['revenue', 'hours'],
  data() {
    let rev = parseInt(this.revenue) || 0;
    let icon = '';
    let color = '';
    if (this.hours) rev = 10 / this.hours * rev;
    if (rev < this.$store.state.user.revenuePerCleanerMin) {
      color = 'red';
      icon = 'emoticon-sad-outline';
    } else if (rev < this.$store.state.user.revenuePerCleaner) {
      color = 'orange';
      icon = 'emoticon-neutral-outline';
    } else {
      color = 'green';
      icon = 'emoticon-happy-outline';
    }
    return {
      color,
      icon,
      title: this.$root.printCost(this.revenue) + (this.hours ? ` за ${this.hours} ч` : '')
    }
  }
}
</script>
