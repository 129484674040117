export default {
  data() {
    let years = [];
    for (let i = 2020; i <= this.$moment().format('YYYY'); i++) {
      years.push((i).toString())
    }
    return {
      rows: [],
      stats: {},
      date: [this.$moment().format('YYYY'), this.$moment().format('MM')],
      headers: [
        {text: 'Имя', value: 'manager.login'},
        {text: 'Всего', value: 'total.sum'},
        {text: 'Уборки/Соц.сети', value: 'standart.sum'},
        {text: 'Удалено/Конф. ситуации', value: 'canceled.sum'},
        {text: 'Опоздания', value: 'manager.late_sessions'},
        {text: 'Смены с пропущенными вызовами'},
        {text: 'Зарплата'}
      ],
      search: '',
      loading: true,
      years,
      salary: null,
      finance: {},
      isCalculateBonuses: [true, true, true, true],
      //['manager_salary', 'bonus_average_bill', 'bonus_average_bill_cleanings', 'bonus_deleted_orders', 'bonus_rate']
      salaryData: {
        manager_salary: 45000,
        bonus_average_bill: 5000,
        bonus_average_bill_cleanings: 7000,
        bonus_deleted_orders: 3000,
        bonus_rate: 3000,
        kpi: {
          bonuses: [15000, 1000, 10000],
        },
        manager_salary_okk: 45000,
        bonus_satisfied_okk: 5000,
        resolve_duration_okk: 2000,
        sales_okk: 5000,
        soc_networks_okk: 25000,
        bonus_kpi_okk: 10000,
      },
      bonusInfo: {
        deleted_orders: 30,
        avrgBill: 7000,
        avgBillStandart: 3800,
        avgBillGeneral: 15000,
        avgBillRemont: 20000,
        rate: 4.9
      },
      salaryCalcEditable: false,
    }
  },
  computed: {
    salaryBonusesEnabled() {
      return +this.$store.state.user.city.id===1
    },
    normalSalary() {
      let personal_bonuses = Object.values(this.salaryData).splice(1)
        .filter((v, i) => this.isCalculateBonuses[i]);
      return this.salaryData.manager_salary + (personal_bonuses.length ? personal_bonuses.reduce((a, b) => a + b) : 0);
    },
    calculateKPI() {
      let basic_kpi = (this.finance.plan - this.finance.plan % 100000) / 2 - 500000 > 0 ?
        (this.finance.plan - this.finance.plan % 100000) / 2 - 500000 : 0;
      let increased_kpi = (this.finance.plan - this.finance.plan % 100000) / 2 > 0 ?
        (this.finance.plan - this.finance.plan % 100000) / 2 : 0;
      return (this.salary - this.normalSalary > 0) && (this.salary - this.normalSalary <= this.salaryData.kpi.bonuses[0]) ? basic_kpi :
        (this.salary - this.normalSalary > this.salaryData.kpi.bonuses[0]) && (this.salary - this.normalSalary <= this.salaryData.kpi.bonuses[0] + (this.salaryData.kpi.bonuses[1] * 5)) ?
          basic_kpi + 100000 * (this.salary - this.normalSalary - this.salaryData.kpi.bonuses[0]) / 1000 :
          this.salary - this.normalSalary > (this.salaryData.kpi.bonuses[1] * 5) ? increased_kpi + (100000 * Math.floor((this.salary - this.normalSalary - (this.salaryData.kpi.bonuses[0] + 5000)) / 10000)) : basic_kpi;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    loadTable() {
      this.rows = [];
      this.loading = true;
      this.$store.state.server.request('order/managers', {year: this.date[0], month: this.date[1]}, (data) => {
        this.loading = false
        this.rows = data.response
        this.stats = data.stats;
      }, () => {
        this.loading = false
      })
    },
    getFinance(year, month) {
      this.finance = {};
      this.$store.state.server.request('finance/get', {
        year,
        month
      }, (data) => {
        this.finance = data.response;
      })
    },
    getBonusParams() {
      let params = Object.keys(this.salaryData);
      let infoParams = Object.keys(this.bonusInfo);
      this.$store.state.server.request('user/getParams',
        {
          city: this.user.city.id,
          names: [...params, ...infoParams]
        }, (data) => {
          params.forEach(param => {
            if (data.response[param] !== null && this.salaryData[param]) this.salaryData[param] = +data.response[param];
          });
          infoParams.forEach(param => {
            if (data.response[param] !== null && this.bonusInfo[param]) this.bonusInfo[param] = +data.response[param];
          })
        })
    },
    isMaxCheck(item) {
      let itemAvgCheck = {check: item.isp.total.sum / item.isp.total.count, manager_id: item.manager.n_id};
      let maxCheck = {check: item.isp.total.sum / item.isp.total.count, manager_id: item.manager.n_id};
      for (let i = 0; i < this.rows.length; i++) {
        let current = this.rows[i];
        if (!current.isp) continue;
        if ((current.isp.total.sum / current.isp.total.count) > itemAvgCheck.check && ( current.isp.total.sum / current.isp.total.count) > maxCheck.check) {
          maxCheck.check = current.isp.total.sum / current.isp.total.count;
          maxCheck.manager_id = current.manager.n_id;
        }
      }
      return itemAvgCheck.manager_id === maxCheck.manager_id;
    },
    calcManagerSalary(item) {
      let manager_salary = +this.salaryData.manager_salary + (this.isBonusRate(item) ? +this.salaryData.bonus_rate : 0) +
        (this.isBonusAvgCheck(item) ? +this.salaryData.bonus_average_bill : 0) +
        (this.isBonusAvgCheckCleanings(item) ? +this.salaryData.bonus_average_bill_cleanings : 0) +
        (this.isBonusDeletedOrders(item) ? +this.salaryData.bonus_deleted_orders : 0) + this.calculateBonusesBasedOnKPI(item);
      let manager_okk_salary = +this.salaryData.manager_salary_okk + (this.isSatisfiedClients(item) ? +this.salaryData.bonus_satisfied_okk : 0) +
        (this.isResolveDuration(item) ? +this.salaryData.resolve_duration_okk : 0) +
        (this.isSalesOKK(item) ? +this.salaryData.sales_okk : 0) +
        (this.isBonusSocialNetworks(item) ? +this.salaryData.soc_networks_okk : 0) +
        (this.isBonusKPI_OKK(item) ? +this.salaryData.bonus_kpi_okk : 0);
      return +item.manager.okk ? manager_okk_salary : manager_salary;
    },
    isBonusRate(item) {
      return item.manager.rate >= this.bonusInfo.rate;
    },
    isBonusAvgCheck(item) {
      if (!item.isp) return false;
      return (item.isp.total.sum / item.isp.total.count ) > this.bonusInfo.avrgBill && this.isMaxCheck(item);
    },
    isBonusAvgCheckCleanings(item) {
      return item.standart.sum / item.standart.count > this.bonusInfo.avgBillStandart &&
        item.general.sum / item.general.count > this.bonusInfo.avgBillGeneral &&
        item.remont.sum / item.remont.count > this.bonusInfo.avgBillRemont;
    },
    isBonusDeletedOrders(item) {
      return parseInt(100 * item.canceled.sum / (item.total.sum + item.canceled.sum)) < this.bonusInfo.deleted_orders;
    },
    calculateBonusesBasedOnKPI(item) {
      let total = item.total.sum;
      let basic_kpi = (this.finance.plan - this.finance.plan % 100000) / 2 - 500000 > 0 ?
        (this.finance.plan - this.finance.plan % 100000) / 2 - 500000 : 0;
      let increased_kpi = (this.finance.plan - this.finance.plan % 100000) / 2 > 0 ?
        (this.finance.plan - this.finance.plan % 100000) / 2 : 0;
      if (total > increased_kpi)
        return this.salaryData.kpi.bonuses[0] + (Math.floor((increased_kpi - total) / 100000) * this.salaryData.kpi.bonuses[1]) +
          Math.floor((total - increased_kpi) / 100000) * this.salaryData.kpi.bonuses[2]
      else if (total >= basic_kpi && total <= increased_kpi)
        return this.salaryData.kpi.bonuses[0] + (Math.floor((increased_kpi - total) / 100000) * this.salaryData.kpi.bonuses[1]);
      else return 0;
    },
    //manager OKK
    isBonusKPI_OKK(item) {
      return item.total.sum > 500000;
    },
    isSatisfiedClients(item) {
      let satisfiedClientsWithBonuses = item.manager.conflict_orders.filter(order=>['bonus', 'reorder'].includes(order.feedback.resolve_type));
      return (satisfiedClientsWithBonuses.reduce((accumulator, currentValue) => accumulator + currentValue.total*1, 0)
        /  item.manager.conflict_orders.reduce((accumulator, currentValue) => accumulator + currentValue.total*1, 0)) > 0.8;
    },
    isResolveDuration(item) {
      let count = 0;
      item.manager.conflict_orders.forEach(order=>{
        let date_start = this.$moment.unix(order.feedback.datetime_conflict_start);
        let date_end = this.$moment.unix(order.feedback.datetime_conflict_end);
        let diff = date_start.diff(date_end, 'days');
        if (diff > 6) count++;
      });
      return count === 0;
    },
    isSalesOKK(item) {
      return item.from_feedback && item.from_feedback.sum && (( item.from_feedback.sum / item.total.sum )* 100 > 10);
    },
    isBonusSocialNetworks(item) {
      if (item.soc_networks && item.soc_networks.sum)
        return item.soc_networks.sum > 750000
          && (item.soc_networks.sum / item.scc_networks.count ) > 5000
          && (item.soc_networks.canceledCount / (item.scc_networks.count + item.soc_networks.canceledCount)) < 0.4
      else return false;
    }
  },
  mounted() {
    this.loadTable();
    this.getFinance(this.date[0], this.date[1]);
    this.getBonusParams();
  }
}
