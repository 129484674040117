<template data-app>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Рекорды</v-card-title>
        <v-card-actions>
          <div style="display: flex">
          <Date min="2023-11-30" v-model="date" @change="load"/>
            <v-checkbox @change="load" v-if="$store.state.user.city.id==1" label="С Москвой" v-model="withMsk"/>
          </div>
        </v-card-actions>
        <v-card-text>
          <v-tabs class="tabs" v-model="tab">
            <v-tab v-for="(title, name) in tabs" :key="name">{{ title }}</v-tab>
          </v-tabs>
          <v-tabs-items style="max-width: 100vw" v-model="tab">
            <v-tab-item v-for="(title, name) in tabs" :key="name">
              <template v-if="sections[name]">
                <RecordCard class="mt-4" v-for="(item, i) in sections[name]" :key="i" :item="item"/>
              </template>
              <div v-else>Загрузка...</div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Date from "../../../components/Date";
import RecordCard from "./components/RecordCard";

export default {
  name: "RecordsPage",
  components: {RecordCard, Date},
  data() {
    return {
      date: this.$moment().format('YYYY-MM-DD'),
      tab: null,
      withMsk: false,
      tabs: {
        absolute: 'Абсолютные',
        month: 'Месяц',
        week: 'Неделя',
        day: 'День',
      },
      sections: {},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store.state.server.request('order/records', {date: this.date, withMsk: this.withMsk}, data => this.sections = data.response);
    }
  }
}
</script>

<style lang="scss">
.tabs {
  .v-slide-group__prev, .v-slide-group__next {
    display: none !important;
  }
}
</style>
<style scoped lang="scss">

.record-section {
  margin-bottom: 80px;

  .section-title {
    margin-bottom: 48px;
    text-align: center;
    color: #AA6B1B;
    font-size: 36px;
    font-weight: 700;
  }

  &.winners-section {
    .section-title {
      color: #5D5D5D;
    }
  }

  .records-items {
    display: flex;
    flex-direction: column;
    gap: 48px;

    &-empty {
      text-align: center;
      font-size: 32px;

      i {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
